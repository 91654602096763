
import React, { useContext, useEffect, useState } from 'react';
import MyContext from '../context/MyContext';
import '../assets/CSS/Donwloadtemple.css'



import logo from "../assets/images/imgpsh_fullsize_anim (2).png"
import arrow from '../assets/images/arrowMeme.png'
import twitter from '../assets/images/twittermeme.png'
import telegram from '../assets/images/Telegrammeme.png'
import tiktok from '../assets/images/Telegrammeme.png'
import youtube from '../assets/images/Youtubememe.png'
import instagram from '../assets/images/Instagram.png'
import warpcast from '../assets/images/Wmeme.png'
import Contract from '../assets/images/Contract.png'
import Database from '../assets/images/Database.png'
import Circulating from '../assets/images/Circulating.png'
import Burned from '../assets/images/Burned.png'
import Liquidity from '../assets/images/Liquidity.png'
import Renounced from '../assets/images/Renounced.png'
import { Images1 } from './MemeCoin/Images';
import { Images2 } from "./MemeCoin/Images";
import { Images3 } from './MemeCoin/Images'
import { Images4 } from "./MemeCoin/Images";
import { Images5 } from "./MemeCoin/Images";
import { Images6 } from "./MemeCoin/Images";
import { Images7 } from "./MemeCoin/Images";
import { Images8 } from "./MemeCoin/Images";
import { Images9 } from "./MemeCoin/Images";
import { Images10 } from './MemeCoin/Images';

import { usePublish } from '../context/publishSiteContext';



const PublishSite = () => {
  const { publishWebsite } = usePublish();
  const { randomId, uniqueID, memecustmonapi, custmondomian,webdomains } = useContext(MyContext);
  const [loading, setLoading] = useState(false);


  const publishWebsiteContent = async () => {

    const publishWebsitecontent = await publishWebsite();


    console.log("publishWebsitecontent", webdomains)
    try {
      const response = await fetch('https://trendifyweb.ai/pyapi/save-html', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          html: publishWebsitecontent,
          filename: `${randomId}.html` }),
      });
      const data = await response.json();

      if (data.success) {
        alert('Website Deployed Successfully');
      } else {
        alert('Failed to  Deploy');
      }
    } catch (error) {
      console.error('Error:', error);
      // alert('An error occurred.',);
    } finally {
      setLoading(false); // Hide the spinner and enable the button
    }

  }

  const validatepublish = async () => {

    setLoading(true);

    ////////////
    const savedValue = localStorage.getItem('wagmi.store');
    // console.log('downloadaddressValue', address)
    // console.log('savedaddressValue  wg', typeof savedValue)
    const savedValuejson = JSON.parse(savedValue)
    const storeaddvalue = savedValuejson.state.connections.value

    let useradderess = null;
    if (storeaddvalue.length > 0) {
      useradderess = storeaddvalue[0][1]?.accounts[0]
      // alert(`storeaddvalue is greater than 0 ${useradderess}`)
    } else {
      alert('Please connect your wallet')
      setLoading(false);
    }
    console.log('2222222222222', useradderess)
    /////////// 

    const cangenerate = await fetch(
      'https://trendifyweb.ai/pyapi/cangenerate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          address: useradderess,
          usecase: 'publish',
          randomid: uniqueID,
        }),
      },
    )
    const cangeneratestatus = await cangenerate.json();
    console.log('cangeneratestatus', cangeneratestatus.can)
    if (!useradderess) {
      alert('Please connect your wallet');
      setLoading(false);
    }
    else if (cangeneratestatus.can === true) {
      publishWebsiteContent();
    } else {
      alert("Please Upgrade your subscription")
      setLoading(false);
    }

  };




  return (
    // <div className='d-flex justify-content-end'>
    //   <button className='buttoncolor' style={{ padding: "8px 16px" }} onClick={validatepublish} >Publish</button>
    // </div>
    <div className='d-flex justify-content-end'>
      <button
        className='buttoncolor'
        style={{ padding: "8px 16px" }}
        onClick={validatepublish}
        disabled={loading} // Disable the button when loading
      >
        {loading ? (
          <div className='spinner-border spinner-border-sm' role='status'>
            <span className='sr-only'></span>
          </div>
        ) : (
          "Publish"
        )}
      </button>
    </div>
  );
}

export default PublishSite;

