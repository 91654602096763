import React, { useContext, useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';
import { useConnect } from 'wagmi';
import MyContext from '../context/MyContext';

const DesignCard = ({ closeCardPopup }) => {
    const [showShadowValues, setShowShadowValues] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isOpenone, setIsopenone] = useState(false);
    const [isOpentwo, setIsopentwo] = useState(false);
    const [isOpenthree, setIsopenthree] = useState(false);
    const [isOpenfour, setIsopenfour] = useState(false);

    const { statevaluecolor, setStatsvaluecolor,
        statsbackgroundcolor, setStatsbackgroundcolor,
        statslabelcolor, setStatslabelcolor,
        statsborder, setStatsborder,selectMemeCoinTemplates,
        statsborderradius, setStatsborderradius,
        statsshowdowrange, setStatsshowdowrange,
        statsbordercolor, setStatsbordercolor,setMemecoinsteptextcolor,
        memecoin,setMemestepbgcolor,
        statsbackgroundcolorT3, setStatsbackgroundcolorT3,
        setT4statsbackground,setcardtextcolor,
    } = useContext(MyContext);
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setOffsetX(e.clientX - e.target.getBoundingClientRect().left);
        setOffsetY(e.clientY - e.target.getBoundingClientRect().top);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            e.preventDefault();
            const popup = document.querySelector('.popup');
            popup.style.left = e.clientX - offsetX + 'px';
            popup.style.top = e.clientY - offsetY + 'px';
        }
    };
    const HandelOpenone = () => {
        setIsopenone(true)
    };
    const HandelOpentwo = () => {
        setIsopentwo(true)
    };

    const HandelOpenthree = () => {
        setIsopenthree(true)
    };
    const HandelOpenfour = () => {
        setIsopenfour(true)
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("sketchPickerDivone");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setIsopenone(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenone]);

    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("two");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setIsopentwo(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpentwo]);


    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("three");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setIsopenthree(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenthree]);

    useEffect(() => {
        function handleClickOutside(event) {
            const target = event.target;
            const divToExclude = document.getElementById("four");

            // Check if the click occurred outside of the specific div
            if (divToExclude && !divToExclude.contains(target)) {
                setIsopenfour(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpenfour]);


    const Handelstatsvaluecolor = (valuecolor) => {
        setStatsvaluecolor(valuecolor.hex);
        setMemecoinsteptextcolor(valuecolor.hex);
        setcardtextcolor(valuecolor.hex);
    };
    const Handelstatslabelcolor = (labelcolor) => {
        setStatslabelcolor(labelcolor.hex)
    };
    const Handelstatsbackgroundcolor = (backgroundcolor) => {
        setStatsbackgroundcolor(backgroundcolor.hex)
        setStatsbackgroundcolorT3(backgroundcolor.hex);
        setT4statsbackground(backgroundcolor.hex) 

    };

    console.log('color', isOpenone, statevaluecolor);
    const HadelStatsborder = (e) => {
        setStatsborder(e.target.value)
    };
    const Handelborderreadious = (e) => {
        setStatsborderradius(e.target.value)
    };
    const Handelstatsshowdo = (e) => {
        setStatsshowdowrange(e.target.value)
    };
    const Handelstatsbordercolor = (bordercolor) => {
        setStatsbordercolor(bordercolor.hex)
    };
    const HandelstatsOne = () => {
        
        setStatsborderradius(10);
        setStatsbackgroundcolor('#ffffff');
        setT4statsbackground('white')
        // setMemestepbgcolor('');
        setStatsvaluecolor('');
        setStatslabelcolor('');
        setStatsshowdowrange(0);
        setStatsbordercolor('white');
        setStatsbackgroundcolorT3('white')


    };

    const Handelstatstwo = () => {
        setStatsborderradius(10);
        setStatsbackgroundcolor('rgb(24, 24, 27)');
        setStatsbackgroundcolorT3('rgb(24, 24, 27)');
        setT4statsbackground('rgb(24, 24, 27)')

        // setMemestepbgcolor('rgb(24, 24, 27)');
        setStatslabelcolor('white')
        setStatsvaluecolor('white');
        setStatsbordercolor('white');
        setStatsborder(0);
        

    }
    const Handelstatsthree = () => {
        setStatsborderradius(10);
        setStatsbackgroundcolor('');
        setStatsbackgroundcolorT3('white');
        setT4statsbackground('white')
        // setMemestepbgcolor('');
        setStatslabelcolor('')
        setStatsvaluecolor('');
        setStatsshowdowrange(20);
        setStatsborder(0);
        setStatsbordercolor('white');

    };
    const Handelstatsfour = () => {
        setStatsborderradius(0)
        setStatsbackgroundcolor('#fcf8f8');
        setStatsbackgroundcolorT3('#fcf8f8');
        setT4statsbackground('#fcf8f8')   
        // setMemestepbgcolor('#fcf8f8');
        setStatslabelcolor('')
        setStatsvaluecolor('');
        setStatsshowdowrange(1);
        setStatsborder(0);
        setStatsbordercolor('white');
    };

    const Handelstatsfive = () => {
        setStatsborderradius(10)
        setStatsbackgroundcolor('#fcf8f8');
        setStatsbackgroundcolorT3('#fcf8f8');
        setT4statsbackground('#fcf8f8');

        // setMemestepbgcolor('#fcf8f8');
        setStatslabelcolor('')
        setStatsvaluecolor('');
        setStatsshowdowrange(10);
        setStatsborder(0);
        setStatsbordercolor('white');

    };
    const Handelstatssix = () => {
        setStatsborderradius(10)
        setStatsbackgroundcolor('rgb(24, 24, 27)');
        setStatsbackgroundcolorT3('rgb(24, 24, 27)');
        setT4statsbackground('rgb(24, 24, 27)');

        // setMemestepbgcolor('rgb(24, 24, 27)');
        setStatslabelcolor('white')
        setStatsvaluecolor('white');
        setStatsshowdowrange(0);
        setStatsborder(0);
        setStatsbordercolor('white');

    };
    const Handelstatsseven = () => {
        setStatsborderradius(0)
        setStatsbackgroundcolor('rgb(21, 26, 56)');
        setStatsbackgroundcolorT3('rgb(21, 26, 56)');
        setT4statsbackground('rgb(21, 26, 56)');

        // setMemestepbgcolor('rgb(21, 26, 56)');
        setStatslabelcolor('white')
        setStatsvaluecolor('white');
        setStatsshowdowrange(0);
        setStatsborder(0);
        setStatsbordercolor('white');

    };
    const Handelstatseight = () => {
        setStatsborderradius(10);
        setStatsbackgroundcolor('#ffffff')
        setStatsbackgroundcolorT3('white')
        setT4statsbackground('white')

        // setMemestepbgcolor('');
        setStatsvaluecolor('');
        setStatslabelcolor('');
        setStatsshowdowrange(10);
        setStatsbordercolor('white')

    };
    console.log('Stats',statsbackgroundcolorT3);

    

    return (
        <>
            <div className="popup" onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}
                style={{ position: 'fixed', left: '40%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, background: '#fff', borderRadius: '7px', boxShadow: '0 4px 30px #0000001a, 0 4px 10px #00000012', cursor: isDragging ? 'grabbing' : 'context-menu', width: '320px' }} >
                <header class="overlayHeader" onMouseDown={handleMouseDown} style={{ cursor: 'drag' }}>
                    <span class="">Card </span>
                    {/* <button class="overlay_clear__EdOFC">Clear</button> */}
                    <button onClick={closeCardPopup} class="button button--small button--clear overlay_overlayClose__GxQ0k">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" width="16" fill="currentColor"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z"></path></svg>
                    </button>
                </header>
                <div className='picker_container__vy82H ' style={{ width: "100%" }}>
                    <ul class="picker_presets__SmbLj">
                        <li>
                            <div style={{
                                backgroundColor: "white",
                                borderRadius: '10px',
                                borderStyle: "solid",
                                borderWidth: "0px",
                                boxShadow: "rgba(0, 0, 0, 0.42) 0px 8px 24px 0px"
                            }} onClick={HandelstatsOne}>

                            </div>
                        </li>
                        <li>
                            <div style={{
                                background: 'rgb(24, 24, 27)',
                                borderRadius: '10px',
                                borderStyle: 'solid',
                                borderWidth: '0px',
                                boxShadow: '0px 8px 24px 0px rgba(0, 0, 0, 0.42)'
                            }} onClick={Handelstatstwo}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'rgb(255, 255, 255)',
                                borderRadius: '10px',
                                borderStyle: 'solid',
                                borderWidth: '0px',
                                borderColor: 'rgb(255, 255, 255)',
                                boxShadow: '0px 19px 30px -10px rgba(21, 21, 25, 0.21)'
                            }} onClick={Handelstatsthree}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'rgb(248, 248, 248)',
                                borderRadius: '0px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgb(239, 239, 239)'
                            }} onClick={Handelstatsfour}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'linear-gradient(45deg, rgb(255, 255, 255) 0%, rgb(241, 242, 243) 100%)',
                                borderRadius: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgb(235, 235, 235)',
                                boxShadow: '0px 19px 30px -10px rgba(21, 21, 25, 0.21)'
                            }} onClick={Handelstatsfive}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'linear-gradient(45deg, rgba(0, 0, 0, 0.78) 0%, rgb(0, 0, 0) 100%)',
                                borderRadius: '20px',
                                borderStyle: 'solid',
                                borderWidth: '0px',
                                borderColor: 'rgb(231, 231, 231)'
                            }} onClick={Handelstatssix}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'rgb(21, 26, 56)',
                                borderRadius: '0px',
                                borderStyle: 'solid',
                                borderWidth: '0px',
                                borderColor: 'rgb(231, 231, 231)'
                            }} onClick={Handelstatsseven}></div>
                        </li>
                        <li>
                            <div style={{
                                background: 'rgb(255, 255, 255)',
                                borderRadius: '10px',
                                borderStyle: 'solid',
                                borderWidth: '1px',
                                borderColor: 'rgb(231, 231, 231)',
                                boxShadow: '0px 19px 30px -10px rgba(21, 21, 25, 0.21)'
                            }} onClick={Handelstatseight}></div>
                        </li>
                    </ul>
                    {/* <div class="picker_setting__OsFeD">
                        <label>{memecoin=='true'?'Text-Color':'Primary &amp; Secondary Text'}</label>

                        <div class="swatch_swatch__7xFNG" style={{ marginLeft: '10px' }} onClick={HandelOpenone}>
                            <div class="swatch_swatchColor__ynsJn" style={{ backgroundColor: statevaluecolor ? `${statevaluecolor}` : null }}> </div>
                            {isOpenone == false ? null : <div style={{ position: "relative", left: -240 }} id='sketchPickerDivone'>
                                <SketchPicker color={statevaluecolor} onChange={Handelstatsvaluecolor}></SketchPicker>
                            </div>}
                        </div>

                        <div class="swatch_swatch__7xFNG" onClick={HandelOpentwo} style={{ marginLeft: '10px', display:memecoin=='true'?'none':"block"}}>
                            <div class="swatch_swatchColor__ynsJn" style={{ backgroundColor: statslabelcolor ? `${statslabelcolor}` : null }} ></div>
                            <div style={{ position: "relative", left: -240 }} id='two'>
                                {isOpentwo == false ? null :
                                    <SketchPicker color={statslabelcolor} onChange={Handelstatslabelcolor}></SketchPicker>
                                }</div>
                        </div>

                    </div> */}
                    <div class="picker_setting__OsFeD"><span>Background</span>
                        <div class="picker_background__YKaIH" style={{ background: statsbackgroundcolor ? `${statsbackgroundcolor}` : "rgb(255, 255, 255)" }} onClick={HandelOpenthree}>

                            {isOpenthree == false ? null : <div style={{ position: "relative", left: -240, zIndex: 1000 }} id='three'>
                                <SketchPicker color={statsbackgroundcolor} onChange={Handelstatsbackgroundcolor}></SketchPicker>
                            </div>}
                        </div>
                    </div>

                    <div class="picker_setting__OsFeD" ><label>Border</label>
                        <input type="number" value={statsborder} onChange={(e) => HadelStatsborder(e)} />

                        <div class="swatch_swatch__7xFNG" style={{ marginLeft: '5px' }} onClick={HandelOpenfour} >
                            <div class="swatch_swatchColor__ynsJn" style={{ backgroundColor: statsbordercolor ? `${statsbordercolor}` : "rgb(231, 231, 231)" }} ></div>

                            {isOpenfour == false ? null : <div style={{ position: 'relative', left: -220, zIndex: 1000 }} id='four'>
                                <SketchPicker color={statsbordercolor} onChange={Handelstatsbordercolor}></SketchPicker>
                            </div>}

                        </div>
                    </div>
                    <div class="picker_setting__OsFeD"><label>BorderRadius</label><input type="number" value={statsborderradius} onChange={(e) => Handelborderreadious(e)} /></div> <div className='shadow_container__AyT9K'>
                        <div className='shadow_slider__GEWDd'>
                            <label>Magic Shadows</label>
                            <input type='range' value={statsshowdowrange} onChange={(e) => Handelstatsshowdo(e)}></input>

                        </div>
                        {/* <button className='detail_openBtn__x6SYz' onClick={() => setShowShadowValues(!showShadowValues)}>
                            <span>Fine-Tune Shadows</span>
                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path><path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"></path></svg>
                        </button>
                        {showShadowValues && (<div className='shadow_values__xp2Js'>
                            <ul className='shadow_shadows__59Hq5'>
                                <li class="shadow_header__MMkCy">
                                    <span>Left</span>
                                    <span>Top</span>
                                    <span>Blur</span>
                                    <span>Spread</span>
                                    <span>Color</span>
                                </li>
                                <li class="shadow_shadow__1dKUp">
                                    <input type="number" placeholder="0" value="" />
                                    <input type="number" placeholder="0" value="1" />
                                    <input type="number" placeholder="0" value="2" />
                                    <input type="number" placeholder="0" value="-5" />
                                    <div class="colors_swatch__gosbA tooltip" data-tooltip="rgba(0,0,0,0.1)"><div class="colors_swatchColor__WaTUr" style={{ backgroundColor: 'red' }}></div></div>
                                    <button class="shadow_delete__-Poz0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14"><path fill="currentColor" d="M13.06.832H9.485V.66C9.485.1,9.023,0,8.459,0H5.522C4.958,0,4.5.1,4.5.66V.832H.939a.608.608,0,0,0-.609.609V2.617a.609.609,0,0,0,.609.609H13.06a.609.609,0,0,0,.609-.609V1.441A.609.609,0,0,0,13.06.832Z"></path><path fill="currentColor" d="M11.782,4.844H2.217a.61.61,0,0,0-.6.685l.981,7.422A1.2,1.2,0,0,0,3.781,14h6.437a1.2,1.2,0,0,0,1.188-1.049l.98-7.422A.609.609,0,0,0,11.782,4.844Z"></path></svg>
                                    </button>
                                </li>
                            </ul>
                        </div>)} */}
                    </div>
                </div>
            </div>


        </>
    )
}

export default DesignCard