import React, { useContext, useEffect, useState } from "react";
import '../assets/CSS/Dashborad.css'
import { red } from "@mui/material/colors";
import MyContext from "../context/MyContext";
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import zIndex from "@mui/material/styles/zIndex";
import '../assets/CSS/Donwloadtemple.css'
import Newlogo from '../assets/images/trendifyweblogo.png'

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from "react-router-dom";
import Icon from '../assets/images/FaviconNew.png'
import { usePublish } from '../context/publishSiteContext';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import { dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Popover } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { Document, Page } from 'react-pdf'
import PDF from '../assets/images/Meme.pdf'






const styleModel = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};
const styleDomain = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,

};

const Dashborad = () => {
    const { subdomain, subdomain1, newdomain,


        memecoinlogoImage, setMemecoinlogoImage,
        memecoinaboutbgImage, memecoinAminitionImage,
        memecoinaboutleftimage, memecoinaboutrightimage,
        memecoinavisionleftimage, memecoinavisionrightimage,
        memecointokenomicsimage, memecoinContractImage,
        memecoinDatabaseImage, memecoinCirculatingImage,
        memecoinBurnedImage, memecoinLiquidityImage,
        memecoinRenouncedImage, memenavbgcolor,
        memeaboutbgcolor, memecointokonomicsbgimage,
        memecoinanvfont, memenavtextcolor,
        memecoinabouttextcolor, memecoinaboutfont,
        memecoinsection, memecoinvisionfont,
        memecoinvisiontextcolor, memecointokenfont,
        memecointoketextcolor, memecoinstepfont,
        memecoinsteptextcolor, randomId, setRandomId,
        iSremove1, setIsremove1,
        iSremove2, setIsremove2,
        iSremove3, setIsremove3,
        iSremove4, setIsremove4,
        iSremove5, setIsremove5,
        iSremove6, setIsremove6,
        newaddtokest, newiconsImage,
        publishsite, setPublishsite,
        setNewdomain, custmondomian, setcustmondomian,
        memecustmonapi, setMemecustmonapi, uniqueID,
        domainupdate, setDomianupdate, setPurchaseplane,
        websiteName,
        webdomains, setWebdomians,
    } = useContext(MyContext);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open1, setOpen1] = React.useState(null);
    const [confirmdelete, setConfirmdelete] = useState(true);
    const [openmodel, setOpenmodel] = React.useState(false);
    const handleOpen = () => setOpenmodel(true);
    const handleClose = () => setOpenmodel(false);

    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const navigate = useNavigate();


    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClickDelete = (event) => {
        setOpen1(open1 ? null : event.currentTarget);
    };


    const Handelnewdomain = (e) => {
        setNewdomain(e.target.value);
    }
    const HandelCustmondomian = (e) => {
        setcustmondomian(e.target.value)
    }

    const HandelConfirm = () => {
        setConfirmdelete(!confirmdelete)
    };



    console.log('publishWebsite', publishsite)
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [isOpen4, setIsopen4] = useState(false)
    const [isOpen, setIsopen] = useState(false);

    const randomIndex = Math.floor(Math.random() * 5);
    const randomIndex1 = Math.floor((Math.random() * 5) + 5);


    // ---------------------------------------------------------------------------------------------
    const { publishWebsite } = usePublish();

    const [showloder, setShowloder] = useState(false);

    const [loading, setLoading] = useState(false);




    useEffect(() => {
        if (domainupdate == 0) {
            setWebdomians(newdomain)
        }
        if (domainupdate == 1) {
            setWebdomians(newdomain)
        }
    }, [newdomain, custmondomian])



    const validateAddDomian = async () => {

        setShowloder(true);

        ////////////
        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        var useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('2222222222222', useradderess)
            const cangenerate = await fetch(
                'https://trendifyweb.ai/pyapi/cangenerate',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        address: useradderess,
                        usecase: 'publish',
                        randomid: uniqueID,
                    }),
                },
            )
            const cangeneratestatus = await cangenerate.json();
            console.log('cangeneratestatus', cangeneratestatus.can)
            if (cangeneratestatus.can === true) {
                AddnewDomian();
            } else {
                alert("Please Upgrade your subscription")
                setShowloder(false);
            }

        } else {
            alert('Please connect your wallet')
            setShowloder(false);
        }
    };


    const AddnewDomian = async () => {

        setShowloder(true);

        const checkSubdomainResponse = await fetch('https://trendifyweb.ai/pyapi/check-subdomain', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                newDomain: `${webdomains}`,


            })
        })

        const checkSubdomainData = await checkSubdomainResponse.json();
        console.log('checkSubdomainData', checkSubdomainData)

        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            // alert(`storeaddvalue is greater than 0 ${useradderess}`)
        } else {
            alert('Please Connect Your Wallet')
        }
        console.log('2222222222222', useradderess)

        if (checkSubdomainData.domain_available === false) {
            alert('This domain is already exist');
            setShowloder(false);
        } else {
            const publishWebsitecontent = await publishWebsite();
            try {
                const response = await fetch('https://trendifyweb.ai/pyapi/change-subdomain', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        oldDomain: `${randomId}.html`,
                        newDomain: `${webdomains}.html`,
                        html: publishWebsitecontent,
                        url: `${domainupdate == 1 ? `${subdomain}${webdomains}${subdomain1}` : custmondomian}`,
                        randomid: `${uniqueID}`,
                        address: useradderess,
                    })
                })

                const data = await response.json();
                console.log('data', data)
                if (data.success) {
                    setRandomId(webdomains)
                   
                    // alert('Subdomain changed successfully!');
                    alert('Subdomain changed successfully');
                    if(domainupdate==0){
                        setcustmondomian(newdomain)
                    }else if(domainupdate==1){
                        setRandomId(newdomain)
                    }
                    
                    setMemecustmonapi(data.success)
                } else {
                    alert('Failed: ' + data.success);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred.',);
            } finally {
                setShowloder(false);
            }
        }
    };



    const HandelDeleteSite = async () => {
        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            alert('Are you sure you want to delete this site?');
            const Deletefile = await fetch('https://trendifyweb.ai/pyapi/delete-site', {

                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify({
                    filename: `${domainupdate == 1 ?randomId:custmondomian}.html`,
                    address: useradderess,
                    url: `${domainupdate == 1 ?'':custmondomian}`,
                    randomid: `${uniqueID}`,


                })
            })
            const Data = await Deletefile.json();
            console.log('Data', Data)
            if (Data.success) {
                alert('Website Deleted Successfully');
                setOpenmodel(false);
                setOpen3(false);

            } else {
                alert(`Failed: ${Data.message}`);

            }
        } else {
            alert('Please connect your wallet')
        }


    }

    const publishWebsiteContent = async () => {

        const publishWebsitecontent = await publishWebsite();
  
  
        console.log("publishWebsitecontent", publishWebsitecontent)
          try {
            const response = await fetch('https://trendifyweb.ai/pyapi/save-html', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ html: publishWebsitecontent, filename: `${randomId}.html` }),
            });
            const data = await response.json();
  
            if (data.success) {
              alert('Website Deployed Successfully');
            } else {
              alert('Failed to  Deploy');
            }
          } catch (error) {
            console.error('Error:', error);
            // alert('An error occurred.',);
          } finally {
            setLoading(false); // Hide the spinner and enable the button
          }
  
      }
  
      const validatepublish = async () => {
  
        setLoading(true);
        
        ////////////
          const savedValue = localStorage.getItem('wagmi.store');
            // console.log('downloadaddressValue', address)
            // console.log('savedaddressValue  wg', typeof savedValue)
            const savedValuejson = JSON.parse(savedValue)
            const storeaddvalue = savedValuejson.state.connections.value
        
            let useradderess = null;
            if (storeaddvalue.length > 0) {
              useradderess = storeaddvalue[0][1]?.accounts[0]
                // alert(`storeaddvalue is greater than 0 ${useradderess}`)
            } else {
                    // alert('Please connect your wallet ')
                    setLoading(false);
                }
            console.log('2222222222222', useradderess)
        /////////// 
        
            const cangenerate = await fetch(
              'https://trendifyweb.ai/pyapi/cangenerate',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  address: useradderess,
                  usecase: 'publish',
                  randomid: uniqueID
                }),
              },
            )
            const cangeneratestatus = await cangenerate.json();
            console.log('cangeneratestatus', cangeneratestatus.can)
            if (!useradderess) {
              alert('Please connect your wallet ');
              setLoading(false);
            }
            else if (cangeneratestatus.can === true) {
              publishWebsiteContent();
            }else{
              alert("Please Upgrade your subscription")
              setLoading(false);
            }
            
          };
  

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const opendelete = Boolean(open1);
    const id1 = opendelete ? 'simple-popper' : undefined;




    const [alignment, setAlignment] = React.useState('web');

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };






    console.log('Domain', newdomain, '2', custmondomian, webdomains)
    const handleCopyClick = () => {
        const textToCopy = domainupdate == 1 ? `${subdomain}${randomId}${subdomain1}` : custmondomian;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                // alert('Text copied to clipboard');
                setTimeout(() => {
                    setAnchorElcopy(null);
                }, 500);
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };


    const [anchorElcopy, setAnchorElcopy] = React.useState(null);

    const handleClickcopy = (event) => {
        setAnchorElcopy(event.currentTarget);
    };

    const handleClosecopy = () => {
        setAnchorElcopy(null);
    };

    const opencopy = Boolean(anchorElcopy);
    const idcopy = opencopy ? 'simple-popover' : undefined;

    console.log('webdomins', custmondomian)
    const GenerateSScertificates = async () => {

        const savedValue = localStorage.getItem('wagmi.store');const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value


        var useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('2222222222222', useradderess)
            const cangenerate = await fetch(
                'https://trendifyweb.ai/pyapi/cangenerate',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        address: useradderess,
                        usecase: 'publish',
                        randomid: uniqueID,
                    }),
                },
            )
            const cangeneratestatus = await cangenerate.json();
            console.log('cangeneratestatus', cangeneratestatus.can)
            if (cangeneratestatus.can === true) {
                const responce = await fetch('https://trendifyweb.ai/pyapi/confirm-SSL', {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        filename: `${custmondomian}`
                    })
        
                });
                alert('SSL certificate generated successfully')
                const SSL = await responce.json();
                console.log('SSL', SSL);
            } else {
                alert("Please Upgrade your subscription")
                setShowloder(false);
            }

        } else {
            alert('Please connect your wallet')
            setShowloder(false);
        }

    }

    const HandelPlanepurachase = async () => {
        
               
        const savedValue = localStorage.getItem('wagmi.store');
        // console.log('downloadaddressValue', address)
        // console.log('savedaddressValue  wg', typeof savedValue)
        const savedValuejson = JSON.parse(savedValue)
        const storeaddvalue = savedValuejson.state.connections.value

        let useradderess = null;
        if (storeaddvalue.length > 0) {
            useradderess = storeaddvalue[0][1]?.accounts[0]
            console.log('useradderess', useradderess)
            // alert(`storeaddvalue is greater than 0 ${useradderess}`)
        } else {
            alert('Please connect your wallet')

        }

        const url = new URL('https://trendifyweb.ai/pyapi/getpublishedsites');
    url.searchParams.append('address', useradderess);


    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    });

    // Check if the response is OK (status code 200-299)
    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    // Parse the JSON response
    const res = await response.json();
    console.log('Response', res);

    // Extract data and update the state
    const data = res?.data;
    setPurchaseplane(data);
   

    

} 
    const openNewTab = () => {
        window.open( `${PDF}`, '_blank', 'noopener,noreferrer'); // Opens a new tab
      };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'hidden', minHeight: '100vh', overflowY: 'hidden' }}>
                <header class="header" style={{ display: "flex", }}>
                    <div class="wr">
                        <a class="logoLink" href="#">
                            <h1 class="logo">
                                <img src={Icon} alt="" style={{ width: '30px', height: '30px' }}></img>

                            </h1></a>
                        <div class="subheader__meta">
                            <Link to='/DashboardSite' style={{ textDecoration: 'none' }}><a class="parent" href="#" >Sites /</a></Link>
                            <h2>
                                <div class="titleWr">{websiteName}<div class="statusBadge_badgeWr__Q2jEs" style={{ marginLeft: '5px' }}>
                                    <a href="#">
                                    </a><div class="statusBadge_upgradeBtnWr__8UNwl" style={{ marginLeft: '5px' }}>

                                    </div>

                                </div>

                                </div>
                            </h2>
                        </div>
                        <div class="spacer">
                        </div>

                    </div>

                    <Link to='/save&edit' style={{ listStyle: 'none', marginRight: 10 }}><a class="button24" href="#">Edit Site</a></Link>
                    <button class="button24 button--primary  " style={{ marginRight: 6 }} disabled={loading} onClick={() => validatepublish()}>
                        <div>Publish</div>
                        {loading == false ? null :
                            <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                            </div>
                        }
                    </button>


                </header>

                <div class='mainContent'>
                    <div class="subheader">
                        <div class="wr">

                            <div class="accessInfo_wrapper__UFUSz">

                            </div>

                        </div></div>

                    {/* <div class="siteOverview">
                        <ul class="siteSummary">
                            <li class="" style={{ width: "25%", position: "relative", }}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" fill="currentColor">
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path d="M6.4 9.2h.2c.77 0 1.4.63 1.4 1.4v7c0 .77-.63 1.4-1.4 1.4h-.2c-.77 0-1.4-.63-1.4-1.4v-7c0-.77.63-1.4 1.4-1.4zM12 5c.77 0 1.4.63 1.4 1.4v11.2c0 .77-.63 1.4-1.4 1.4-.77 0-1.4-.63-1.4-1.4V6.4c0-.77.63-1.4 1.4-1.4zm5.6 8c.77 0 1.4.63 1.4 1.4v3.2c0 .77-.63 1.4-1.4 1.4-.77 0-1.4-.63-1.4-1.4v-3.2c0-.77.63-1.4 1.4-1.4z"></path></svg>
                                    <span>Status</span>
                                </div>
                                <span>Never Published</span>
                            </li>

                        </ul>
                    </div> */}



                    <h3 class="siteSubtitle" style={{ position: "relative", left: 32 }}>Settings</h3>

                    <div class='wr site' >
                        <div class='siteSettings' style={{ position: "relative", left: 2 }}>
                            <div class="siteSetting" aria-describedby={id} type="button" onClick={handleOpen2} >
                                <div class="siteSettings__header">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="currentColor">
                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                        <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z"></path></svg>
                                    <span class="title">Domains</span>
                                    {domainupdate == 1 ? <span class="value">{memecustmonapi == true ? `${subdomain}${randomId}${subdomain1}` : `${subdomain}${randomId}${subdomain1}`}</span> :
                                        <span class="value">{memecustmonapi == true ? custmondomian : `${subdomain}${randomId}${subdomain1}`}</span>}
                                    <div class="chevron"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
                                        <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"></path></svg>
                                    </div>
                                </div>
                            </div>

                            <Modal
                                open={open2}
                                onClose={handleClose2}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={styleDomain}>
                                    <div class="siteSetting__editor">
                                        <div>You can add custom and trendifyweb.ai domains as you wish.</div>
                                        <div class="domain_tableWrapper__72HcU">
                                            <table class="domain_table__IEp2C">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                        </th>
                                                        <th>
                                                        </th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="item_row__SupYn">
                                                        {domainupdate == 1 ? <td class="item_domain__pOAEG">{memecustmonapi == true ? `${subdomain}${randomId}${subdomain1}` : `${subdomain}${randomId}${subdomain1}`}</td> : <td class="item_domain__pOAEG">{memecustmonapi == true ? custmondomian : `${subdomain}${randomId}${subdomain1}`}</td>}
                                                        <td class="item_value__bJ4Mz" style={{ cursor: 'pointer' }}>
                                                            <button class="label_label__C9xzy label_green__moUU2" style={{ marginLeft: '5px', cursor: 'pointer' }} onClick={handleCopyClick}>
                                                                <Button aria-describedby={idcopy} variant="" onClick={handleClickcopy}>
                                                                    <FontAwesomeIcon icon={faCopy} />
                                                                </Button>
                                                                <Popover
                                                                    id={idcopy}
                                                                    open={opencopy}
                                                                    anchorEl={anchorElcopy}
                                                                    onClose={handleClosecopy}
                                                                    anchorOrigin={{
                                                                        vertical: 'bottom',
                                                                        horizontal: 'left',
                                                                    }}
                                                                >
                                                                    <Typography sx={{ p: 1 }}>Copied</Typography>
                                                                </Popover>
                                                            </button>
                                                        </td>

                                                        <td>
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#3abc60"><path d="M0 0h24v24H0V0z" fill="none"></path>
                                                                <path d="M9 16.17L5.53 12.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L20.29 7.71c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L9 16.17z"></path>
                                                            </svg> */}
                                                        </td>
                                                        <td>
                                                        </td>
                                                    </tr>
                                                </tbody></table></div>
                                        <div class="add_add__FBOKG">

                                            <div class="add_inputWrapper__oWCh-">

                                                <div class="add_input__7NdKi">
                                                    <ToggleButtonGroup
                                                        color="primary"
                                                        value={alignment}
                                                        exclusive
                                                        onChange={handleChange}
                                                        aria-label="Platform"

                                                    ></ToggleButtonGroup>
                                                    <ul class="styles_select__avWeW" style={{ width: '250px' ,position:'relative',left:'20%'}}>
                                                        <Stack spacing={2} direction="row">
                                                            <li class=" ">
                                                                {domainupdate == 0 ? <Button variant="contained" onClick={() => setDomianupdate(0)} style={{ backgroundColor: 'rgb(240,240,240)', color: 'black', width: '200px' }}>custom Domain</Button> :
                                                                    <Button variant="outlined" onClick={() => setDomianupdate(0)} style={{ borderColor: 'rgb(240,240,240)', color: 'black', width: '200px' }}>custom Domain</Button>}
                                                            </li>
                                                            <li class="" >
                                                                {domainupdate == 1 ? <Button variant="contained" onClick={() => setDomianupdate(1)} style={{ backgroundColor: 'rgb(240,240,240)', color: 'black', width: '250px' }}>Trendifyweb Domain</Button> :
                                                                    <Button variant="outlined" onClick={() => setDomianupdate(1)} style={{ borderColor: 'rgb(240,240,240)', color: 'black', width: '250px' }}>Trendifyweb Domain</Button>
                                                                    }

                                                            </li>
                                                            
                                                        </Stack>
                                                    </ul>
                                                    



                                                </div>

                                            </div>

                                            {/* <div class="add_actionWrapper__mb8xh">
                                                <div class="add_action__PudvC">
                                                    <button class="button " style={{ backgroundColor: ((newdomain) || (custmondomian)) ? '#1d474f' : "", color: "white" }} disabled={showloder} onClick={() => validateAddDomian()}>
                                                        <div>Add Domain</div>
                                                        {(((newdomain) || (custmondomian)) && showloder == true) ?
                                                            <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                                                            </div> : null
                                                        }
                                                    </button>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                    {/* <div class="add_value__U2U0r">
                                                            <input type="text" placeholder="" value={newdomain} onChange={Handelnewdomain}></input><span class="add_suffix__oF0Gu"><span></span>.trendifyweb.ai</span>
                                                </div> */}
                                    <div class="input-group mb-3">
                                        <input type="text" class="form-control" placeholder={domainupdate == 0 ? "example.com" : "example"} value={ newdomain } onChange={Handelnewdomain} aria-label="Recipient's username" aria-describedby="basic-addon2"></input>
                                        <div class="input-group-append">
                                            {domainupdate == 1 ? <span class="input-group-text" id="basic-addon2">.trendifyweb.ai</span> : null}

                                        </div>
                                    </div>
                                    <div class="add_actionWrapper__mb8xh">
                                                <div class="add_action__PudvC">
                                                    <button class="button " style={{ backgroundColor: ((newdomain) || (custmondomian)) ? '#1d474f' : "", color: "white",left:'65%',position:'absolute' }} disabled={showloder} onClick={() => validateAddDomian()}>
                                                        <div>Add Domain</div>
                                                        {(((newdomain) || (custmondomian)) && showloder == true) ?
                                                            <div class="spinner-border border-1" role="status" style={{ height: 17, width: 16, marginLeft: 3 }}>
                                                            </div> : null
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                   <Button variant="outlined" onClick={openNewTab} style={{ borderColor: '#064126d6', color: 'black', left:'85%', }}>View Docs</Button>

                                    {(newdomain!='') && (domainupdate == 0) ?
                                        <Card sx={{ width: '35%', }}>
                                            <CardContent>
                                                <Typography>
                                                    <h5>Step:1</h5>
                                                    <p style={{ fontSize: 15 }}>Set up a <b>type A Record</b> with your <b>DNS provider</b> with <b>@</b> and the <b>IP: 45.32.157.183</b></p>
                                                    <h5>Step:2</h5>
                                                    <p style={{ fontSize: 15 }} >Generate <span style={{ color: 'black',fontWeight:600 }}>SS L  Certificate </span></p>
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button onClick={openNewTab} >View Docs</Button>

                                                <Button size="small" onClick={GenerateSScertificates}>Generate</Button>
                                                
                                            </CardActions>
                                        </Card> : null}
                                        
                                                                    
                                                             
                                </Box>
                            </Modal>


                        </div>
                    </div>
                    <h3 class="siteSubtitle" style={{ position: "relative", left: 40, color: 'red', }}>Danger Zone</h3>
                    <div class="siteSettings" style={{ width: '39%', position: 'relative', left: 30, height: 'auto', }}>
                        <div class="siteSetting" aria-describedby={id1} type="button" onClick={handleOpen3}>
                            <div class="siteSettings__header">
                                <span class="title">Delete Site</span>
                                <div class="chevron">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="currentColor">
                                        <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
                                        <path d="M15.88 9.29L12 13.17 8.12 9.29c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41-.39-.38-1.03-.39-1.42 0z"></path>
                                    </svg>
                                </div>
                            </div>


                        </div>
                        <Popper id={id1} open={opendelete} anchorEl={open1} >
                            <Box sx={{ p: 1, bgcolor: 'background.paper', width: "50%", position: 'relative', }}>

                                <div class="siteSetting__editor"  >
                                    <div class="siteSetting__row">
                                        <div class="value">
                                            <label>
                                               
                                                Confirm Deletion</label>
                                        </div>
                                        <p class="info">By clicking the delete button below, your site will immediately become offline. The current version and any backups will be deleted.<strong>This can not be undone</strong>.</p>
                                    </div><div class="siteSetting__actions">
                                        {confirmdelete ?
                                            <button class="button  button--danger" disabled="" onClick={handleOpen}>Delete Site</button> :
                                            <button class="button button--disabled button--danger" disabled="">Delete Site</button>}

                                    </div>
                                </div>

                            </Box>
                        </Popper>

                        <Modal
                            open={open3}
                            onClose={handleClose3}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={styleDomain}>
                                <div class="siteSetting__editor"  >
                                    <div class="siteSetting__row">
                                        <div class="value">
                                            <label>
                                                {/* <input type="checkbox" onClick={HandelConfirm}></input> */}
                                                Confirm Deletion</label>
                                        </div>
                                        <p class="info">By clicking the delete button below, your site will immediately become offline. The current version and any backups will be deleted.<strong>This can not be undone</strong>.</p>
                                    </div><div class="siteSetting__actions">
                                        {confirmdelete ?
                                            <button class="button  button--danger" disabled="" onClick={handleOpen}>Delete Site</button> :
                                            <button class="button button--disabled button--danger" disabled="">Delete Site</button>}

                                    </div>
                                </div>
                            </Box>
                        </Modal>

                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={openmodel}
                            onClose={handleClose}
                            closeAfterTransition
                            slots={{ backdrop: Backdrop }}
                            slotProps={{
                                backdrop: {
                                    timeout: 500,
                                },
                            }}
                        >
                            <Fade in={openmodel}>
                                <Box sx={styleModel}>
                                    <div class="modalContent">
                                        <h2>Confirm Deletion</h2>
                                        <div style={{ width: 300 }}>
                                            <p>Are you sure you want to delete this site? This can not be undone.</p>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button class="button" onClick={handleClose}>Close</button>
                                                <button class="button button--danger" onClick={HandelDeleteSite}>Delete Site</button>
                                            </div></div><div class="modalActions"></div></div>
                                </Box>
                            </Fade>
                        </Modal>

                    </div>



                </div>

                <footer class="footer">
                    <div class="wr">
                        <div class="footerContent">

                            <div>
                                <div class="_c0e4633f ">© 2024 Trendifyweb.ai</div>
                                <div class="_a6d0f97b mt-1" >
                                </div>

                            </div>
                            <div class="footerList">
                                <ul><li>Home</li>
                                </ul>
                                <ul><li>Pricing</li>

                                </ul>
                                <ul>
                                    <li>Features</li>
                                </ul>
                                <ul><li>EN</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

            </div>




        </>
    )
};

export default Dashborad;