import React, { useContext, useEffect, useState } from "react";
import MainNavBar from "../../MainNavBar";
import ContentDesign from "../../ContentDesign";
import MyContext from "../../../context/MyContext";
import MemeVisionDesing from "./Memecoinvisiondesing";
import Theme from "../../Theme";
import Memecoin from "../Memecoin";
import MemeStepdesing from "./Memecoinstepdesing";
import twitter from '../../../assets/images/twittermeme.png'
import telegram from '../../../assets/images/Telegrammeme.png'
import tiktok from '../../../assets/images/Telegrammeme.png'
import youtube from '../../../assets/images/Youtubememe.png'
import instagram from '../../../assets/images/Instagram.png'
import warpcast from '../../../assets/images/Wmeme.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaTwitter } from "react-icons/fa";
import { SketchPicker } from "react-color";
import Memefooterdesing from "./Memefooterdesing";
import axios from "axios";
import { useAccount } from "wagmi";
import { faToggleOff, faToggleOn, faX } from "@fortawesome/free-solid-svg-icons";


const Memefooteredit = () => {
  const { contentdesing, mainnavbarbutton,
    memefooterabout, setMemefooterabout,
    memetwitterlink, setMemetwitterlink,
    memetelegramlink, setMemetelegramlink,
    memeYoutubelink, setMemeYoutubelink,
    memetiktoklink, setMemetiktoklink,
    memeinstagramlink, setMemeinstagramlink,
    memewarpcastlink, setMemewarpcastlink,
    memefooterbgimage, setMemefooterbgimage,
    memefooterbgcolor, setMemefooterbgcolor,
    setMemefootrbackground, uniqueID, selectMemeCoinTemplates,
    template1footerabout, setTemplate1footerAbout,
    template1footerImage, setTemplate1FooterImage,
    memecoinsymbol,
    template2footerlogo, setTemplate2footerlogo,
    template2footeringo, setTemplate2footerinfo,
    template3footerinfo, setTemplate3Footerinfo,
    template3footerheading, setTemplate3footerheading,
    template3uniswaplink, setTemplate3uniswaplink,
    template3telegramlink, setTemplate3telegramlink,
    template3twitterlink, setTemplate3twitterlink,
    template3email, setTemplate3Email,
    template3memeaddress, setTemplate3memeadress,
    t4footer1, setT4footer1,
    t4footer2, setT4footer2,
    t4footer3, setT4footer3,
    t4footer4, setT4footer4,
    t1social1, Stet1social1,
    t1social, Stet1social,
    t1footer, Stet1footer,
    telegraminage, setTelegraminage,
    twitterimage, setTwitterimage,
    instagramimage, setInstagramimage,
    warpcastimage, setWarpcastimage,
    youtubeimage, setYoutubeimage,
    tiktokimage, setTiktokimage,
    twittertextt4,setTwittertextt4,
    Dextoolstext,setDextoolstext,
    telegramtext,setTelegramtext,
    removemedia1,setRemovemedia1,
      removemedia2,setRemovemedia2,
      removemedia3,setRemovemedia3,
      removemedia4,setRemovemedia4,
      removemedia5,setRemovemedia5,
      removemedia6,setRemovemedia6,

  } = useContext(MyContext);
  const [isHeaderDesignText, setisHeaderDesignText] = useState(false);

  // API to store meme footer content data. For testing purpose using use effect

  const { address } = useAccount()

  useEffect(() => {
    const updateMemefooterContentData = async () => {
      const response = await axios.post('https://trendifyweb.ai/pyapi/updateMemefooterContentData', {
        params: {
          address: address,
          siteid: uniqueID,
          memefooterabout: memefooterabout,
          memetwitterlink: memetwitterlink,
          memetelegramlink: memetelegramlink,
          memeYoutubelink: memeYoutubelink,
          memetiktoklink: memetiktoklink,
          memeinstagramlink: memeinstagramlink,
          memewarpcastlink: memewarpcastlink,
          memefooterbgimage: memefooterbgimage,
          memefooterbgcolor: memefooterbgcolor,
          template1footerabout: template1footerabout,
          template1footerImage: template1footerImage,
          template2footerlogo: template2footerlogo,
          template2footeringo: template2footeringo,
          template3footerinfo: template3footerinfo,
          template3footerheading: template3footerheading,
          template3uniswaplink: template3uniswaplink,
          template3telegramlink: template3telegramlink,
          template3twitterlink: template3twitterlink,
          template3email: template3email,
          template3memeaddress: template3memeaddress,
          t4footer1: t4footer1,
          t4footer2: t4footer2,
          t4footer3: t4footer3,
          t1social1: t1social1,
          t1social: t1social,
          t1footer: t1footer,
          telegraminage: telegraminage,
          twitterimage: twitterimage,
          instagramimage: instagramimage, 
          warpcastimage: warpcastimage,
          youtubeimage: youtubeimage,
          tiktokimage: tiktokimage,
          Dextoolstext: Dextoolstext,
          telegramtext: telegramtext
          
        }
      })
      console.log('memefooterContentAPIresponse', response)
    }
    updateMemefooterContentData()
  }, [memefooterbgcolor])


  const handleBackgrounddImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...memefooterbgimage];
        updatedTrustLogo[index] = event.target.result;
        setMemefooterbgimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }
  const handelchangecolor = (titlecolor) => {
    setMemefooterbgcolor(titlecolor.hex)
    // if ((titlecolor !== 'white') || (titlecolor.hex !== '#000000')) {
    //     setnavbartextcolor('white')
    // }
    // if ((titlecolor == '#ffffff') || (titlecolor.hex == '#ffffff')) {
    //     setnavbartextcolor('Black')
    // }
  };

  const handeltitle = () => {
    setisHeaderDesignText(!isHeaderDesignText)
  };
  useEffect(() => {
    function handleClickOutside(event) {
      const target = event.target;
      const divToExclude = document.getElementById('one');

      // Check if the click occurred outside of the specific div
      if (divToExclude && !divToExclude.contains(target)) {
        setisHeaderDesignText(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isHeaderDesignText]);

  const handleLogoImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...template1footerImage];
        updatedTrustLogo[index] = event.target.result;
        setTemplate1FooterImage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleTwitterImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...twitterimage];
        updatedTrustLogo[index] = event.target.result;
        setTwitterimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };

  const handleTelegramImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...telegraminage];
        updatedTrustLogo[index] = event.target.result;
        setTelegraminage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleInastagramImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...instagramimage];
        updatedTrustLogo[index] = event.target.result;
        setInstagramimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  };
  const handleYoutubeImage= (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...youtubeimage];
        updatedTrustLogo[index] = event.target.result;
        setYoutubeimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handleTiktokImage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...tiktokimage];
        updatedTrustLogo[index] = event.target.result;
        setTiktokimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }

  const handlewarpcastimage = (index) => {
    // Open file picker to select an image
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedTrustLogo = [...warpcastimage];
        updatedTrustLogo[index] = event.target.result;
        setWarpcastimage(updatedTrustLogo);
        console.log('updatedTrustLogo', updatedTrustLogo)
      };

      reader.readAsDataURL(file);
    };
    input.click();
  }
  return (
    <>
      <>
        <div className='container-fluid d-flex flex-column' style={{ height: '100vh', overflow: 'auto' }}>
          <div>
            <MainNavBar />
          </div>
          <div className='flex-grow-1 d-flex flex-row mt-1' style={{ overflow: 'hidden' }}>
            <div style={{ width: '400px' }} >
              {mainnavbarbutton == false ?
                <div className='editing_wrapper__Wijfx h-100  overflow-auto'>
                  <ContentDesign headerText="Footer" />
                  {contentdesing == false ?
                    <div >
                      {selectMemeCoinTemplates == 1 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>About</span>
                                        <textarea placeholder="about" style={{ height: "110px" }} value={memefooterabout} onChange={(e) => setMemefooterabout(e.target.value)}>
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq mt-3" >
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>Twitter</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia1(!removemedia1)}><FontAwesomeIcon icon={removemedia1==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                       

                                        <textarea placeholder="Twitter" style={{ height: "32px" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)}>
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Twitter Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {twitterimage.length > 0 ?
                                            <div>
                                              {twitterimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTwitterImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTwitterImage(0) }} style={{ height: 30, width: 40 }} src={twitterimage.length > 0 ? twitterimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 

                                      </div>
                                      <FontAwesomeIcon icon={FaTwitter} />
                                      <div class="form_field__d31bq mt-3" >
                                        
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>Telegram</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia2(!removemedia2)}><FontAwesomeIcon icon={removemedia2==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                        <textarea placeholder="Telegram" style={{ height: "32px" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Telegram Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {telegraminage.length > 0 ?
                                            <div>
                                              {telegraminage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTelegramImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTelegramImage(0) }} style={{ height: 30, width: 40 }} src={telegraminage.length > 0 ? telegraminage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                    
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>You Tube</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia3(!removemedia3)}><FontAwesomeIcon icon={removemedia3==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                        <textarea placeholder="Youtube" style={{ height: "32px" }} value={memeYoutubelink} onChange={(e) => setMemeYoutubelink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>You Tube Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {youtubeimage.length > 0 ?
                                            <div>
                                              {youtubeimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleYoutubeImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleYoutubeImage(0) }} style={{ height: 30, width: 40 }} src={youtubeimage.length > 0 ? youtubeimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                        
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>Instagram</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia4(!removemedia4)}><FontAwesomeIcon icon={removemedia4==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                        <textarea placeholder="Instagram" style={{ height: "32px" }} value={memeinstagramlink} onChange={(e) => setMemeinstagramlink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Instagram Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {instagramimage.length > 0 ?
                                            <div>
                                              {instagramimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleInastagramImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleInastagramImage(0) }} style={{ height: 30, width: 40 }} src={instagramimage.length > 0 ? instagramimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>

                                      <div class="form_field__d31bq mt-3" >
                                      
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>Tiktok</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia5(!removemedia5)}><FontAwesomeIcon icon={removemedia5==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                        <textarea placeholder="Instagram" style={{ height: "32px" }} value={memetiktoklink} onChange={(e) => setMemetiktoklink(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <div className="media_preview__okAyH">
                                        <label>Tiktok Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {tiktokimage.length > 0 ?
                                            <div>
                                              {tiktokimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleTiktokImage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleTiktokImage(0) }} style={{ height: 30, width: 40 }} src={tiktokimage.length > 0 ? tiktokimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>

                                      <div class="form_field__d31bq mt-3" >
                                    
                                        <div style={{ display: "flex" ,width:"100%",justifyContent:"space-between"}}>
                                        <span>Warpcast</span> <span style={{cursor:"pointer"}} onClick={()=>setRemovemedia6(!removemedia6)}><FontAwesomeIcon icon={removemedia6==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} /></span>
                                        </div>
                                        <textarea placeholder="warpcast " style={{ height: "32px" }} value={memewarpcastlink} onChange={(e) => setMemewarpcastlink(e.target.value)}>
                                        </textarea>
                                        <br></br>
                                        <div className="media_preview__okAyH">
                                        <label>Warpcast Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {warpcastimage.length > 0 ?
                                            <div>
                                              {warpcastimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handlewarpcastimage(0) }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handlewarpcastimage(0) }} style={{ height: 30, width: 40 }} src={warpcastimage.length > 0 ? warpcastimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div> 
                                      </div>
                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                      {selectMemeCoinTemplates == 2 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>About</span>
                                        <textarea placeholder="Text" style={{ height: "110px" }} value={template1footerabout} onChange={(e) => setTemplate1footerAbout(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Caption</span>
                                        <textarea placeholder="Text" style={{ height: "30px" }} value={t1footer} onChange={(e) => Stet1footer(e.target.value)}>
                                        </textarea>
                                      </div>


                                      <br />
                                      <div className="media_preview__okAyH">
                                        <label> Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {template1footerImage.length > 0 ?
                                            <div>
                                              {template1footerImage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLogoImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={template1footerImage.length > 0 ? template1footerImage : null} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                      {selectMemeCoinTemplates == 3 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>About</span>
                                        <textarea placeholder="about" style={{ height: "110px" }} value={template2footeringo} onChange={(e) => setTemplate2footerinfo(e.target.value)}>
                                        </textarea>
                                      </div>


                                      <br />

                                      <div class="form_field__d31bq mt-3" >
                                        <span>Year</span>
                                        <textarea placeholder="Text" value={template2footerlogo} onChange={(e) => setTemplate2footerlogo(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                      


                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                      {selectMemeCoinTemplates == 4 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Heading</span>
                                        <textarea placeholder="Heading" style={{ height: "32px" }} value={template3footerheading} onChange={(e) => setTemplate3footerheading(e.target.value)}>
                                        </textarea>
                                      </div>
                                      <br />
                                      <div class="form_field__d31bq mt-3" >
                                        <span>About</span>
                                        <textarea placeholder="about" style={{ height: "110px" }} value={template3footerinfo} onChange={(e) => setTemplate3Footerinfo(e.target.value)}>
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq mt-3" >
                                        <div style={{ display: "flex" ,justifyContent:"space-between",alignItems:"center",cursor:"pointer"}}><span>Twitter</span> <FontAwesomeIcon icon={removemedia1==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} onClick={()=>setRemovemedia1(!removemedia1)}/></div>
                                        
                                        <textarea placeholder="Twitter" style={{ height: "32px" }} value={twittertextt4} onChange={(e) => setTwittertextt4(e.target.value)}>
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={template3twitterlink} onChange={(e) => setTemplate3twitterlink(e.target.value)}>
                                        </textarea>

                                      </div>
                                      <FontAwesomeIcon icon={FaTwitter} />
                                      <div class="form_field__d31bq mt-3" >
                                      <div style={{ display: "flex" ,justifyContent:"space-between",alignItems:"center",cursor:"pointer"}}><span>Telegram</span> <FontAwesomeIcon icon={removemedia2==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} onClick={()=>setRemovemedia2(!removemedia2)}/></div>

                                        
                                        <textarea placeholder="Telegram" style={{ height: "32px" }} value={telegramtext} onChange={(e) => setTelegramtext(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={template3telegramlink} onChange={(e) => setTemplate3telegramlink(e.target.value)} >
                                        </textarea>
                                        
                                      </div>
                                      <div class="form_field__d31bq mt-3" >
                                      <div style={{ display: "flex" ,justifyContent:"space-between",alignItems:"center",cursor:"pointer"}}><span>DexTools</span> <FontAwesomeIcon icon={removemedia3==true?faToggleOff:faToggleOn} style={{width:"30px",height:"30px"}} onClick={()=>setRemovemedia3(!removemedia3)}/></div>

                                        
                                        <textarea placeholder="DexTools" style={{ height: "32px" }} value={Dextoolstext} onChange={(e) => setDextoolstext(e.target.value)} >
                                        </textarea>
                                        <br/>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={template3uniswaplink} onChange={(e) => setTemplate3uniswaplink(e.target.value)} >
                                        </textarea>
                                      </div>

                                      {/* <div class="form_field__d31bq mt-3" >
                                        <span>Email</span>
                                        <textarea placeholder="Email" style={{ height: "32px" }} value={template3email} onChange={(e) => setTemplate3Email(e.target.value)} >
                                        </textarea>
                                      </div> */}

                                      <div class="form_field__d31bq mt-3" >
                                        <span>Address</span>
                                        <textarea placeholder="Address" style={{ height: "62px" }} value={template3memeaddress} onChange={(e) => setTemplate3memeadress(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <br />
                                      <label>Meme Image</label>
                                      <div className="media_preview__okAyH">
                                        <label> Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {template1footerImage.length > 0 ?
                                            <div>
                                              {template1footerImage.map((image, index) => (
                                                <img
                                                  onClick={() => handleLogoImage(0)}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => handleLogoImage(0)} style={{ height: 30, width: 40 }} src={template1footerImage.length > 0 ? template1footerImage : null} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}
                      {selectMemeCoinTemplates == 6 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                       
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={t4footer1} onChange={(e) => setT4footer1(e.target.value)}>
                                        </textarea>
                                        <br />
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={t4footer2} onChange={(e) => setT4footer2(e.target.value)}>
                                        </textarea>
                                        <br />
                                        <textarea placeholder="Text" style={{ height: "fit-content" }} value={t4footer3} onChange={(e) => setT4footer3(e.target.value)}>
                                        </textarea>
                                      </div>


                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label> Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}

                      {selectMemeCoinTemplates == 5 ? <div className="editing_content__RXzxk">
                        <div className="form_form__Sku4X" >
                          <div className="form_form__Sku4X">
                            <div className="form_group__kDXNb">
                              <div className="form_form__Sku4X">
                                <div class="form_row__2+BJ3">

                                  <div className="form_group__kDXNb">
                                    <div className="form_form__Sku4X" style={{ maxWidth: "89%" }}>
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Social</span>

                                        <textarea placeholder="Heading" style={{ height: "32px" }} value={t1social} onChange={(e) => Stet1social(e.target.value)}>
                                        </textarea>

                                      </div>
                                      <br />
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Caption</span>

                                        <textarea placeholder="Caption" style={{ height: "32px" }} value={t1social1} onChange={(e) => Stet1social1(e.target.value)}>
                                        </textarea>

                                      </div>
                                      <br />

                                      <div class="form_field__d31bq mt-3" >
                                        <span>Twitter</span>

                                        <textarea placeholder="Link" style={{ height: "32px" }} value={memetwitterlink} onChange={(e) => setMemetwitterlink(e.target.value)}>
                                        </textarea>

                                      </div>
                                      <FontAwesomeIcon icon={FaTwitter} />
                                      <div class="form_field__d31bq mt-3" >
                                        <span>Telegram</span>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={memetelegramlink} onChange={(e) => setMemetelegramlink(e.target.value)} >
                                        </textarea>
                                      </div>

                                      <div class="form_field__d31bq mt-3" >
                                        <span>Instagram</span>
                                        <textarea placeholder="Link" style={{ height: "32px" }} value={memeinstagramlink} onChange={(e) => setMemeinstagramlink(e.target.value)} >
                                        </textarea>
                                      </div>



                                      <br />
                                      <span>Background Image</span>
                                      <div className="media_preview__okAyH">
                                        <label>Background Image</label>
                                        <div className="media_value__\+4KKG ">
                                          {memefooterbgimage.length > 0 ?
                                            <div>
                                              {memefooterbgimage.map((image, index) => (
                                                <img
                                                  onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }}
                                                  key={index}
                                                  style={{ height: 30, width: 40 }}
                                                  src={image}
                                                  alt={`Image ${index}`}
                                                  className="media_image__kN9DM"
                                                />
                                              ))}</div>
                                            :
                                            <img onClick={() => { handleBackgrounddImage(0); setMemefootrbackground(1); }} style={{ height: 30, width: 40 }} src={memefooterbgimage.length > 0 ? memefooterbgimage : ''} alt="" className="media_image__kN9DM" />
                                          }
                                        </div>
                                      </div>
                                      <br />
                                      <span >Background Color</span>

                                      <div className="media_preview__okAyH" onClick={() => { handeltitle(); setMemefootrbackground(0) }}>
                                        <label>Background Color</label>
                                        <div className="media_value__\+4KKG ">

                                          <div style={{ height: 30, width: 40, backgroundColor: memefooterbgcolor.length == 0 ? '' : memefooterbgcolor, position: 'relative' }} className="media_image__kN9DM" ></div>

                                        </div>


                                      </div>
                                      {isHeaderDesignText == false ? null :
                                        <div id='one' style={{ position: 'relative', }}>
                                          <SketchPicker color={memefooterbgcolor} onChange={handelchangecolor}></SketchPicker></div>
                                      }

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div> : null}


                    </div>
                    : <Memefooterdesing />}

                </div> : <div className='editing_wrapper__Wijfx h-100  overflow-auto'><Theme /></div>}
            </div>
            <div className=' h-100 overflow-auto editing_wrapper__Wijfxnew' style={{ width: 'calc(100% - 400px)' }}>
              <Memecoin />
            </div>
          </div>
        </div>
      </>
    </>
  )
};

export default Memefooteredit;