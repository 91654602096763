import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/FaviconNew.png"
import MyContext from "../context/MyContext";
import { useMediaQuery } from 'react-responsive';
import '../assets/CSS/Navbar.css';

import Logo from '../assets/images/FaviconNew.png'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

function Navbar() {



    const { navbtn, setnavbtn,
        headerdivtextcolor, setHeaderDivTextColor,
        headerdivtext, headerdivbgcolor,
        setHeaderdivbgcolor, navbartitle,
        setNavbartitle, headerdiv,
        buttonbordercolor,
        Section1, title,
        button1font,
        titlecolor, contenttextheadingcolor,
        butttonshowdo,
        navbarmenu, addnavmenu,
        buttoncolor,
        navbtn1, setNavbtn1,
        conatcmenuborder,
        navbtn2, setNavbtn2,
        intromenubgcolor, buttonborderradius,
        navremovebtn1, navremovebtn2,
        buttonborder,
        headertextfont,
        setHeadertextfont, intromenutextcolor,
        navAddbutton, addbuttonapprovel,
        buttonshowdoleft,
        newlogo, showdowrange,
        setnavbarmenu,
        selectedLi, setSelectedLi,
        buttonvarticalpadding,
        setButtonvarticalpadding,
        newnavbarmenu, setNewnavbarmenu,
        buttonshowdoTop, buttonshowdospread,
        buttonhorizantlepadding, buttonshowdoblur,
        setButtonhorizantlepadding, buttonshowdowcolor,
        introcolor,
        checked, setChecked } = useContext(MyContext);
    console.log('titlecolor', titlecolor)
    console.log('contenttextheadingcolor', contenttextheadingcolor);
    console.log('navbar', showdowrange);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };



    const handleClose = () => {
        setIsOpen(false);
    };



    // const handleClickOutsidePopup = (event) => {
    //     const popup = document.querySelector('.um-nav-drawer');
    //     const closeBtn = document.querySelector('.um-nav-drawerClose');

    //     if (isOpen && !popup.contains(event.target) && event.target !== closeBtn) {
    //         handleClose();
    //     }
    // };

    // React.useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutsidePopup);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutsidePopup);
    //     };
    // }, [isOpen]);
    useEffect(() => {
        const test1 = newnavbarmenu.slice(3, 4);
        console.log('navbardata', test1, typeof test1)
        setNavbtn1(test1);
        const test2 = newnavbarmenu.slice(4, 5);
        console.log('navbardata', test2, typeof test2);
        setNavbtn2(test2);
    }, [newnavbarmenu])

    console.log('navbardata', newnavbarmenu, typeof navbarmenu, navbtn1, navbtn2)
    useEffect(() => {
        const test3 = newnavbarmenu.slice(0, 3);
        setnavbarmenu(test3)
    }, [newnavbarmenu]);

    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `${r}, ${g}, ${b}`;
    };
    const Navbarmenu = navbarmenu.map((item) => {
        return item
    })

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    return (

        <div class="container" style={{ minWidth: '100%', }} >
            {headerdiv == true ?
                <div style={{ width: '100%', height: 20, backgroundColor: `${headerdivbgcolor}`, color: `${headerdivtextcolor}`,display:'flex',justifyContent:'center',alignItems:'center', fontFamily: `${headertextfont}`, }}>{headerdivtext}</div> : 
                null}


            {selectedLi === null && (
                <nav class="container   navbar-expand-lg  " style={{ backgroundColor: `${titlecolor}`, borderBottom: `1px solid ${conatcmenuborder}`, minWidth: '100%' }}>
                    <div className="Navbarcontainer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxShadow: `0px ${showdowrange}px ${showdowrange}px rgba(0, 0, 0, 0.1)`, }}  >

                        <span class="navbar-brand" href="#">
                            {newlogo.length > 0 ?
                                <img src={newlogo} height="30px" width="50px" alt="Logo" class="me-1" /> :

                                <img src={Logo} height="30px" width="30px" alt="Logo" class="me-1" />}
                            <h3 class="d-inline " style={{ marginLeft: 10, fontSize: 20, backgroundColor: `${titlecolor}`, color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}` }}>{navbartitle}</h3>
                        </span>
                        <button class="navbar-toggler " type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>


                        <div class="collapse navbar-collapse" id="navbarNav" style={{ marginRight: 20 }}>

                            <ul class="navbar-nav ms-auto" style={{ fontSize: 14, backgroundColor: `${titlecolor}`, justifyContent: 'center', justifyItems: "center" }}>

                                {Array.isArray(navbarmenu) &&
                                    navbarmenu.map((item, index) => (
                                        <li className="nav-item" key={index}  >
                                            <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                {item}
                                            </span>

                                        </li>
                                    ))}


                                <li class="nav-item" style={{ display: navremovebtn1 ? 'none' : '', }}>
                                    <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" style={{  color: `${contenttextheadingcolor}`,fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button></a>
                                </li>
                                <li class="nav-item" style={{ display: navremovebtn2 ? 'none' : '', marginLeft: 5 }}>
                                    <a class="nav-link  m-0 p-0" href="#"><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }}>{navbtn2}</button></a>
                                </li>
                                {navAddbutton.map((button, index) => (
                                    <li className="nav-item" key={index} style={{ display: addbuttonapprovel == false ? 'none' : '' ,marginLeft: 5}}>
                                        <a className="nav-link m-0 p-0" href="#">
                                            <button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }}> {button}</button>
                                        </a>
                                    </li>
                                ))}
                            </ul>

                        </div>
                    </div>
                </nav>
            )}


            {selectedLi === 0 && (
                <div class="container  navbar-expand-lg  " style={{ backgroundColor: `${introcolor}`, borderBottom: `1px solid ${conatcmenuborder}` }}>
                    <section className="_58ce83d8 ">
                        <div className="wr">
                            <div className="_18a9f441">
                                <div className="_f6f5267b" style={{ backgroundColor: `${titlecolor}`, border: `1px solid ${conatcmenuborder}`, minWidth: '33rem' }}>
                                    <div className="um-nav-wrapper">
                                        <nav class="um-nav-children">
                                            <div class="_32caed59" style={{ gap: '1px', fontSize: '14px' }} >
                                                <a>
                                                    <div class="_8c82ce03" style={{ scale: '1' }}>
                                                        <img src={newlogo.length>0?newlogo:logo} class="_b9923f60" alt="11.svg" />
                                                    </div>
                                                    <span class="_11650ac9" style={{ marginLeft: 10, color: 'black', fontSize: 20, backgroundColor: `${titlecolor}`, color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}` }}>{navbartitle}</span>
                                                </a>
                                            </div>
                                        </nav>
                                    </div>
                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                        {(popupState) => (
                                            <div>
                                                <Button  {...bindTrigger(popupState)}>
                                                    <button onClick={handleToggle} class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>

                                                </Button>
                                                <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Typography sx={{ p: 2 }} style={{display:'felx',justifyContent:'center',alignItems:'center', backgroundColor: `${titlecolor}`,}}> 
                                                        <ul class="navbar-nav ms-auto" style={{ fontSize: 14, backgroundColor: `${titlecolor}`, justifyContent: 'center', justifyItems: "center",cursor: 'pointer' }}>

                                                        {Array.isArray(navbarmenu) &&
                                                            navbarmenu.map((item, index) => (
                                                                <li className="nav-item" key={index}  >
                                                                    <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                                        {item}
                                                                    </span>

                                                                </li>
                                                            ))}


                                                        <li class="nav-item" style={{ display: navremovebtn1 ? 'none' : '', }}>
                                                            <a class="nav-link  " href="#" ><button class="buttoncolor" style={{color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button></a>
                                                        </li>
                                                        <li class="nav-item" style={{ display: navremovebtn1 ? 'none' : '', }}>
                                                            <a class="nav-link  " href="#" ><button class="buttoncolor" style={{color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}</button></a>
                                                        </li>
                                                        
                                                        {navAddbutton.map((button, index) => (
                                                            <li className="nav-item" key={index} style={{ display: addbuttonapprovel == false ? 'none' : '' }}>
                                                                <a className="nav-link m-0 p-0" href="#">
                                                                    <button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`,fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `0px 0px ${butttonshowdo}px 0px rgba(21, 21, 25, 0.21)`, width: '100%', }}> {button}</button>
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                    </Typography>
                                                </Popover>
                                            </div>
                                        )}
                                    </PopupState>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {selectedLi === 1 && (
                <div class="container  navbar-expand-lg  " style={{ backgroundColor: `${introcolor}`, borderBottom: `1px solid ${conatcmenuborder}` }}>
                    <div className="_58ce83d8 "  >
                        <div className="wr " style={{ maxWidth: "100%" }}>
                            <div className="_f4c879d8" style={{ backgroundColor: `${titlecolor}`, border: `1px solid ${conatcmenuborder}` }}>
                                <div class="_9698a7cf" style={{ gap: '1px', fontSize: '16px' }}>
                                    <a class="d-flex align-items-center" >
                                        <div class="_f7a17dd3" style={{ scale: '1' }}>
                                            <img src={newlogo.length>0?newlogo:logo}class="_246d637b" style={{ width: '24px', height: '24px' }} alt="11.svg" /></div>
                                        <span class="_3c86000f" style={{ marginLeft: 10, color: 'black', fontSize: 20, backgroundColor: `${titlecolor}`, color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}` }}>{navbartitle}</span>
                                    </a>
                                </div>
                                <ul className='um-nav-links'>
                                {Array.isArray(navbarmenu) &&
                                    navbarmenu.map((item, index) => (
                                        <li className="nav-item" key={index}  >
                                            <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                {item}
                                            </span>

                                        </li>
                                    ))}
                                
                                <li class="nav-item" style={{ display: navremovebtn1 ? 'none' : '', }}>
                                    <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" style={{  color: `${contenttextheadingcolor}`,fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button></a>
                                </li>
                                <li class="nav-item" style={{ display: navremovebtn2 ? 'none' : '', }}>
                                    <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}</button></a>
                                </li>
                                        {navAddbutton.map((button, index) => (
                                            <li class="nav-item" style={{ display: addbuttonapprovel == false ? 'none' : '' }}>
                                            <a class="nav-link  m-0 p-0" href="#" ><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{button}</button></a>
                                        </li>
                                        ))}
                                    
                                    
                                </ul>
                                
                                <button class="um-nav-drawerTrigger drawerTrigger" onClick={handleToggle} title="Open Mobile Menu"></button>
                                {isOpen && (<div class="_e45b5504 um-nav-drawer">
                                    <header>
                                        <button class="um-nav-drawerClose" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                        </button>
                                    </header>
                                    <div class="um-drawer-links">
                                    {Array.isArray(navbarmenu) &&
                                        navbarmenu.map((item, index) => (
                                            <li className="nav-item" key={index}  >
                                                <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                    {item}
                                                </span>

                                            </li>
                                        ))}
                                    </div>
                                    <div class="um-drawer-buttons">
                                        <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn">
                                            <span style={{ fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</span>
                                        </button>
                                        <button id="d_btn_gni5jwa3za" class="_89517563 btn">
                                            <span style={{ fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}
                                            </span>
                                        </button>
                                        {navAddbutton.map((button, index) => (
                                            <button id="d_btn_gni5jwa3za" class="_89517563 btn">
                                            <span style={{color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{button}
                                            </span>
                                        </button>
                                        ))}
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            )}

            {selectedLi === 2 && (
                <div className="_58ce83d8 " style={{ backgroundColor: `${titlecolor}`, borderBottom: `1px solid ${conatcmenuborder}` }}>
                    <div className="_5859e61e ">
                        <div className="_157dc3d3 ">
                            <nav class="_e45b5504 um-nav-wrapper um-classic" >
                                <div class="um-nav-children">
                                    <div class="_32caed59" style={{ gap: '1px', fontSize: '14px' }} >
                                        <a>
                                            <div class="_8c82ce03" style={{ scale: '1' }}>
                                                <img src={newlogo.length>0?newlogo:logo} class="_b9923f60" alt="11.svg" />
                                            </div>
                                            <span class="_11650ac9" style={{ fontFamily: `${headertextfont}`, color: `${contenttextheadingcolor}`, }}>{navbartitle}</span>
                                        </a>
                                    </div>
                                </div>
                                <ul className='um-nav-links'>
                                {Array.isArray(navbarmenu) &&
                                        navbarmenu.map((item, index) => (
                                            <li className="nav-item" key={index}  >
                                            <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                {item}
                                            </span>

                                        </li>
                                        ))}
                                </ul>
                                <div class="um-drawer-buttons" style={{ display: 'flex', margin: '5px', gap: '5px' }}>
                                    <a class="nav-link  m-0 p-0" href="#" style={{ display: navremovebtn1 ? 'none' : '', }} ><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button></a>

                                    <a class="nav-link  m-0 p-0" href="#" style={{ display: navremovebtn2 ? 'none' : '', }} ><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}</button></a>
                                    {navAddbutton.map((button, index) => (
                                    <a class="nav-link  m-0 p-0" href="#" >
                                        <button class="buttoncolor" style={{color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{button}</button></a>

                                        ))}
                                </div>
                            </nav>

                        </div>
                    </div>

                </div>
            )}


            {selectedLi === 3 && (
                <div style={{ borderBottom: `1px solid ${conatcmenuborder}` }}>
                    <section className="_58ce83d8 " style={{ position: 'absolute', }}>
                        <div className="wr" style={{ margin: "30px auto 0px 0px" }} >
                            <div className="_18a9f441" style={{ justifyContent: 'start', }}>
                                <div className="_f6f5267b" style={{ backgroundColor: `${titlecolor}`, border: `1px solid ${conatcmenuborder}`, minWidth: '33rem' }}>
                                    <div className="um-nav-wrapper">
                                        <nav class="um-nav-children">
                                            <div class="_32caed59" style={{ gap: '1px', fontSize: '14px' }} >
                                                <a>
                                                    <div class="_8c82ce03" style={{ scale: '1' }}>
                                                        <img src={newlogo.length>0?newlogo:logo} class="_b9923f60" alt="11.svg" />
                                                    </div>
                                                    <span class="_11650ac9" style={{ fontFamily: `${headertextfont}` }}>{navbartitle}</span>
                                                </a>
                                            </div>
                                        </nav>
                                    </div>
                                    <button onClick={handleToggle} class="um-nav-drawerTrigger" title="Open Mobile Menu"></button>
                                    {isOpen && (
                                        <div class="_e45b5504 um-nav-drawer" style={{ backgroundColor: `${titlecolor}`, }}>
                                        <header style={{ backgroundColor: `${titlecolor}`, }}>
                                            <button class="um-nav-drawerClose" onClick={handleClose}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                                            </button>
                                        </header>
                                        <div class="um-drawer-links" style={{ zIndex: 1000, backgroundColor: `${titlecolor}`, }}>
                                        {Array.isArray(navbarmenu) &&
                                        navbarmenu.map((item, index) => (
                                            <li className="nav-item" key={index}  >
                                                <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                    {item}
                                                </span>

                                            </li>
                                        ))}
                                        </div>
                                        <div class="um-drawer-buttons" >
                                            <button id="d_btn_x2cz0o6ryp" class="_e72e75b2 btn"
                                                style={{color: `${contenttextheadingcolor}`,display: navremovebtn1 ? 'none' : '',  fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button>
                                            <button id="d_btn_gni5jwa3za" class="_89517563 btn"
                                                style={{color: `${contenttextheadingcolor}`,display: navremovebtn2 ? 'none' : '',  fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}

                                            </button>

                                            {navAddbutton.map((button, index) => (
                                   <button id="d_btn_gni5jwa3za" class="_89517563 btn"
                                   style={{color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{button}

                               </button>
                                        ))}
                                            
                                        </div>
                                    </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            )}

            {selectedLi === 4 && (
                <div className="_58ce83d8 " style={{ backgroundColor: `${titlecolor}`, borderBottom: `1px solid ${conatcmenuborder}` }} >
                    <div className="_5859e61e ">
                        <div className="_157dc3d3 px-3">
                            <nav class="_e45b5504 um-nav-wrapper um-classic">
                                <div class="um-nav-children">
                                    <div class="_32caed59" style={{ gap: '1px', fontSize: '14px' }} >
                                        <a>
                                            <div class="_8c82ce03" style={{ scale: '1' }}>
                                                <img src={newlogo.length>0?newlogo:logo} class="_b9923f60" alt="11.svg" />
                                            </div>
                                            <span class="_11650ac9" style={{ fontFamily: `${headertextfont}`,color: `${contenttextheadingcolor}`, }}>{navbartitle}</span>
                                        </a>
                                    </div>
                                </div>
                                <ul className='um-nav-links'>
                                {Array.isArray(navbarmenu) &&
                                        navbarmenu.map((item, index) => (
                                            <li className="nav-item" key={index}  >
                                                <span className="nav-link" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${headertextfont}`, margin: 5, }}  >
                                                    {item}
                                                </span>

                                            </li>
                                        ))}
                                </ul>
                                <div class="um-drawer-buttons" style={{ display: 'flex', margin: '5px', gap: '5px' }}>
                                    <a class="nav-link  m-0 p-0" href="#" style={{ display: navremovebtn1 ? 'none' : '', }}><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn1}</button></a>

                                    <a class="nav-link  m-0 p-0" href="#" style={{ display: navremovebtn2 ? 'none' : '', }}><button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`, fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{navbtn2}</button></a>
                                    {navAddbutton.map((button, index) => (
                                    <a class="nav-link  m-0 p-0" href="#" >
                                        <button class="buttoncolor" style={{ color: `${contenttextheadingcolor}`,fontFamily: `${button1font}`, backgroundColor: `${buttoncolor}`, border: `${buttonborder}px solid ${buttonbordercolor}`, borderRadius: `${buttonborderradius}px`, boxShadow: `${buttonshowdoleft}px ${buttonshowdoTop}px ${butttonshowdo}px ${buttonshowdospread}px rgba(${hexToRgb(buttonshowdowcolor)}, 0.${buttonshowdoblur})`, width: '100%', paddingTop: `${buttonvarticalpadding}px`, paddingBottom: `${buttonvarticalpadding}px`, paddingLeft: `${buttonhorizantlepadding}px`, paddingRight: `${buttonhorizantlepadding}px` }} >{button}</button></a>

                                        ))}
                                </div>
                            </nav>

                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};

export default Navbar;