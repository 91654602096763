import React, { useContext, useEffect, useRef, useState } from "react";
import Boomer from '../../assets/images/logo2.png';
import cartoon from '../../assets/images/leftflying.png'
import boomerright from '../../assets/images/boomerright.png'
import boomerleft from '../../assets/images/carttonleft.png'
import arrow from '../../assets/images/arrowMeme.png'
import aboutright from '../../assets/images/aboutright.png'
import aboutleft from '../../assets/images/aboutleft.png'
import '../../assets/CSS/Memecoin.css'
import Backgroundimage from '../../assets/images/menubackground.png'
import heroImage from '../../assets/images/BackgroundMemecoin1.png'
import MyContext from "../../context/MyContext";
import twitter from '../../assets/images/twittermeme.png'
import telegram from '../../assets/images/Telegrammeme.png'
import tiktok from '../../assets/images/tiktok.png'
import youtube from '../../assets/images/Youtubememe.png'
import instagram from '../../assets/images/Instagram.png'
import warpcast from '../../assets/images/Wmeme.png'
import Contract from '../../assets/images/Contract.svg'
import Database from '../../assets/images/Database.svg'
import Circulating from '../../assets/images/Circulating.png'
import Burned from '../../assets/images/Burned.png'
import Liquidity from '../../assets/images/Liquidity.svg'
import Renounced from '../../assets/images/Renounced.svg'
import tokonmocismask from '../../assets/images/tokonomicsmask.png'
import Tokonemoicsbgimage from '../../assets/images/tokonmicsbg.svg'
import UrlNav from "../UrlNav";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import DownloadMemecoinTemplate from "../DownloadMemecoin";
import { Image2 } from "./Images";
import CloudImage from '../../assets/images/NewMaskImage.png'

import styles from '../../assets/CSS/Template1.module.css';
import '../../assets/CSS/Template2.css';
import '../../assets/CSS/Template3.css'
import '../../assets/CSS/T4.css'
import stylesT5 from '../../assets/CSS/T5.module.css'

import Twitter from '../../assets/images/TwitterT1.png'
import MainImage from '../../assets/images/Template1MainImage.png'
import Tokenomic1 from '../../assets/images/TokenomicImage2T1.png'
import Tokenomics2 from '../../assets/images/TokenomicIamge1T1.png'
import Tokenomics3 from '../../assets/images/TokenomicImage3T1.png'
import ContractImage1 from '../../assets/images/ContractIamge1T1.png'
import ContractImage2 from '../../assets/images/ContractImage1T1.png'
import ContractImage3 from '../../assets/images/ContractImageIamge1T1.png'
import FooterIamge from '../../assets/images/Template1footerimage.png'
import BackgroundImage from '../../assets/images/SollybackgroungT1.png'
import Solly from '../../assets/images/Template1Solly.png'


import Template2Logo from '../../assets/images/Template2Logo.png'
import Template2telegram from '../../assets/images/Template2Telegram.png'
import Template2twitter from '../../assets/images/Template2twitter.png'
import Template2unicorn from '../../assets/images/Template2unicorn.png'
import Template2M from '../../assets/images/Template2M.png'
import Template2Forg from '../../assets/images/Template2forg.png'
import Template2sectionbg from '../../assets/images/Template2Sectionbg.png'
import Template2Section3bg from '../../assets/images/Template2Section3bg.png'
import Template2SectionMain from '../../assets/images/Template2SectionMainbg.png'


import Template3BG from '../../assets/images/BackgroundImageT3.jpg'
import Template3Container from '../../assets/images/ContainerT3.svg'
import Template3Sunppd from '../../assets/images/SunppdT3.png'
import Template3Telegram from '../../assets/images/TelegramT3.png'
import Template3Twitter from '../../assets/images/Template3x.png'
import Template3Chart from '../../assets/images/Template3Chart.png'
import Template3Svg from '../../assets/images/Template3Svg.png'
import Template3BrownContainer from '../../assets/images/Template3BrownContainer.png'
import Template3Vector from '../../assets/images/Template3Vector.png'
import Template3Maks from '../../assets/images/Template3Mask.png'
import Template3MaskSvg from '../../assets/images/Template3Makssvg.png'
import Template3footersvg from '../../assets/images/Template3Footersvg.png'
import Template3footerbg from '../../assets/images/Template3Footerbg.jpg'
import Template3Footercontainer1 from '../../assets/images/Template3Footercontainer1.png'
import Template3FooterContainer2 from '../../assets/images/Template3FooterContainer2.png'
import Template3Footertwitter from '../../assets/images/Template3Footertwitter.png'
import Template3FooterSunppd from '../../assets/images/Template3FooterSunppd.png'
import Template3FOoterEmail from '../../assets/images/Template3FooterEmail.svg'
import Template3SectionSnuppd from '../../assets/images/Template3Sectionsnuppd.png'
import Template3Section3svg from '../../assets/images/Template3Section3svg.png'
import Template3DogImage from '../../assets/images/Template3Dogimage.png'


import T4Navlogo from '../../assets/images/T4Navlogo.png'
import T4MainSectionImage from '../../assets/images/images/T4MainsectionBackground.png'
import T4Memelogo from '../../assets/images/T4MemeImage.png'
import T4MemeImage1 from '../../assets/images/T4MemeImage2.png'
import T4twitter from '../../assets/images/T4Twitter.png'
import T4telegram from '../../assets/images/T4telegram.png'
import T4Instagram from '../../assets/images/T4instagram.png'
import T4trend from '../../assets/images/T4trend.png'
import T4Section3bgImage from '../../assets/images/T4Section2Image.jpg'
import T4MemeImage2 from '../../assets/images/T4MemeLogo2.png'
import T4MemeImage3 from '../../assets/images/T4MemeImage3.png'
import T4footertelegram from '../../assets/images/T4Footertelegram.png'
import T4footerTwitter from '../../assets/images/T4FooterTwitter.png'
import T4footerInstagram from '../../assets/images/T4FooterInstagram.png'
import T4footertrend from '../../assets/images/T4FooterTrend.png'
import { HelmetProvider } from "react-helmet-async";


import T5image1 from '../../assets/images/T5Image1.png'
import T5image2 from '../../assets/images/T5image2.png'
import T5image3 from '../../assets/images/T5image3.png'
import T5image4 from '../../assets/images/T5image4.png'
import T5image5 from '../../assets/images/T5image5.png'
import T5image6 from '../../assets/images/T5image6.png'
import T5image7 from '../../assets/images/T5image7.png'
import T5image8 from '../../assets/images/T5image8.png'
import T5image9 from '../../assets/images/T5image9.png'
import T5image10 from '../../assets/images/T5image10.png'
import T5image11 from '../../assets/images/T5image11.png'
import T5image12 from '../../assets/images/T5image12.png'
import T5image13 from '../../assets/images/T5image13.png'
import T5image14 from '../../assets/images/T5image14.png'
import T5image15 from '../../assets/images/T5image15.png'
import T5image16 from '../../assets/images/T5image16.png'
import T5image17 from '../../assets/images/T5image17png.png'
import T5image18 from '../../assets/images/T5image18.png'
import T5image19 from '../../assets/images/T5image19.png'
import T5image20 from '../../assets/images/T5image20.png'
import T5image21 from '../../assets/images/T5image21.png'
import T5image22 from '../../assets/images/T5image22.png'
import T5image23 from '../../assets/images/T5image23.png'
const Memecoin = () => {
    const {
        memecoinname, SetMemecoinName,
        memecoinsymbol, setMemecoinSymbol,
        memecoinNavbar, setMemecoinNavbar,
        memecoinAbout, setMemecoinAbout,
        memeCaption, memecoinValue, newIntroImage,
        memecoincontractaddress, setMemecoincontractAddress,
        memecoinInitialsupply, setMemecoinInitialsupply,
        memecoinCirculatingSupply, setMemecoinCirculatingSupply,
        memecoinSupplyBurned, setMemecoinSupplyBurned,
        memecoinLiquidity, setMemecoinLiquidity,
        memecoinlogoImage, setMemecoinlogoImage,
        memecoinaboutbgImage, memecoinAminitionImage,
        memecoinaboutleftimage, memecoinaboutrightimage,
        memecoinavisionleftimage, memecoinavisionrightimage,
        memecointokenomicsimage, memecoinContractImage,
        memecoinDatabaseImage, memecoinCirculatingImage,
        memecoinBurnedImage, memecoinLiquidityImage,
        memecoinRenouncedImage, memenavbgcolor,
        memeaboutbgcolor, memecointokonomicsbgimage,
        memecoinanvfont, memenavtextcolor,
        memecoinabouttextcolor, memecoinaboutfont,
        memecoinsection, memecoinvisionfont,
        memecoinvisiontextcolor, memecointokenfont,
        memecointoketextcolor, memecoinstepfont,
        memecoinsteptextcolor, randomId, setRandomId,
        iSremove1, setIsremove1,
        iSremove2, setIsremove2,
        iSremove3, setIsremove3,
        iSremove4, setIsremove4,
        iSremove5, setIsremove5,
        iSremove6, setIsremove6,
        newaddtokest, newiconsImage,
        step1, setStep1,
        step2, setStep2,
        step3, setStep3,
        step4, setStep4,
        stepheading, setStepheading,
        memeherobutton,
        memefooterabout, setMemefooterabout,
        memetwitterlink, setMemetwitterlink,
        memetelegramlink, setMemetelegramlink,
        memeYoutubelink, setMemeYoutubelink,
        memetiktoklink, setMemetiktoklink,
        memeinstagramlink, setMemeinstagramlink,
        memewarpcastlink, setMemewarpcastlink,
        setMemecoinsection, memeaboutbgimage,
        memestepbgimage, memefooterbgimage,
        memenavbarbgcolor, memebackground,
        memeherobgcolor, memeherobackground,
        memeaboutbackground, memetokenomicsbgcolor,
        memetokenomicsbackground, memestepbgcolor,
        memestepbackground, memefooterbgcolor,
        memefootrbackground, setMemefootrbackground,
        tokenvalubgcolor, settokenvalubgcolor,
        memefootertextcolor, memefootertextfont, memenavfontsize,
        memeMainfontsize, memeaboutfontSize, memetokenfontSize,
        memestepfontSize, memefooterfontSize, selectMemeCoinTemplates,
        contractRenounced, showWebsite, modalShow,
        setMemeherobgcolor, setMemeherobackground,
        setMemenavbarbgcolor, setMemebackground,
        template1caption, template1Info, setMemetokenomicsbgcolor, setMemetokenomicsbackground,
        template1tokenimage2, template1tokenimage3,
        template1tokenomics, template1tokenomicssub1,
        template1tokenomicssub2, template1tokenomicssub3,
        template1tokenomisupply, template1tokenomicontract,
        template1tokenomiTax, template1footerabout,
        template1footerImage, statsbackgroundcolor,
        statsborder, statsbordercolor,
        statsborderradius, statsshowdowrange,
        setMemestepbgcolor, setMemestepbackground,
        template1stepAdd, template1stepcontract,
        template2footerlogo, template2footeringo,
        template2stepheading, template2step1,
        template2step2, template2step3,
        template2step4, setMemestepbgimage,
        setMemecointokonomicsbgimage,
        template2tokenheading, template2tokensupply,
        template2tokentatesupply, template2tokentax,
        template2tokenlp, template2tokenMeme,
        setmemeAboutbgimage, setMemeaboutbackground,
        template2telegramlink, template2twitterlink,
        template2caption, template2memecaption,
        template2info, template2tokenAddress,
        template2uniswap, template2cmc, template2coingecko,
        template3telegramlink, template3twitterlink,
        template3uniswaplink, template3heading,
        template3section1, template3Section2,
        template3Section3, template3Section4,
        template3stepimage, template3stepquestion,
        template3stepsection1, template3stepsection2,
        template3step3section2heading,
        template3stepsection2bg, template3stepsection2color,
        memestepbackgroundt3, template3footerheading,
        template3footerinfo, template3email, template3memeaddress,
        statsbackgroundcolorT3, memecloudimage,
        memetokencaption, memetokenbuy,
        template2removebuttonuniswap, template2removebuttonunicmc,
        template2removebuttoncoingecko, t4navbutton,
        t4memeaddress, t4caption,
        t4mainbutton1, t4mainbutton2,
        t4token, t4step1,
        t4step2, t4step3,
        t4step4, t4step5,
        t4footer1, t4footer2,
        t4footer3, t4footer4,

        t4stepimage,
        t5Nav1, setT5Nav1,
        t5nav2, sett5Nav2,
        t5about1, t5about2,
        t5Token1,
        t5Token3, t5Token4,
        t5Token5, t5Token6,
        t5step1, t5step2,
        t5step3, t5step4,
        t1stepheading, buy, buy1,
        t1social, t1social1,
        step1heading, step2heading, step3heading, step4heading,
        t1token1, t1token,
        t4statsbackgrounsd,
        t1footer, stepimage3, stepimage2, stepimage1,
        buybuttonbgcolor, setbuybuttonbgcolor,
        buybuttnbgImage, setbuybuttnbgImage,
        memebuybuttonbackground, introtextfont,
        telegramtext, twittertext,
        telegraminage, twitterimage,
        youtubeimage, instagramimage,
        tiktokimage, warpcastimage,
        stepnumber1, setStepnumber1,
        stepnumber2, setStepnumber2,
        stepnumber3, setStepnumber3,
        stepnumber4, setStepnumber4,
        stepheading1, setStepheading1,
        stepheading2, setStepheading2,
        stepheading3, setStepheading3,
        stepheading4, setStepheading4,
        memeabout, cardtextcolor,
        t4stepheading1, t4stepheading2,
        t4stepheading3, t4stepheading4,
        uniswapimage, setUniswapImage,
        cmcimage, setCmcImage,
        coingeckoimage, setCoingeckoImage,
        template2stepheading1, setTemplate2stepheading1,
        template2stepheading2, setTemplate2stepheading2,
        template2stepheading3, setTemplate2stepheading3,
        template2stepheading4, setTemplate2stepheading4,
        template2tokenbutton, template2mainbutton1,
         template2mainbutton2,twittertextt4,Dextoolstext,
         tokenheading1T3,tokenheading2T3,tokenheading3T3,tokenheading4T3,
         tokenheadingt3,removemedia1,
         removemedia2,removemedia3,
         removemedia4,removemedia5,
         removemedia6,removemedia7,
         dexscannerlink,setDexscannerlink
    } = useContext(MyContext);



    const [shownewicon, setshownewicon] = useState(false)
    const memecoinRef = useRef(null);
    const [location, setLocations] = useState();


    const Navbar = memecoinNavbar.map((item) => {
        return item
    })



    if (memecoinNavbar.length < 4) {
        var Newmenubar = [...memecoinNavbar]
    }
    console.log('Memecoin', memeCaption.slice(13, 32))
    const Caption1 = memeCaption.slice(0, 13);
    const Caption2 = memeCaption.slice(13,);

    console.log('memeCount', memecoinsection);
    useEffect(() => {
        if (memecoinsection == 1) {
            window.location.href = '#navbar'
            window.location.href = '#Navbar'
            // window.location.href = '#Weburl'
        }
        if (memecoinsection == 2) {
            window.location.href = '#hero'
            window.location.href = '#Hero'

            // window.location.href = '#Weburl'


        }
        if (memecoinsection == 3) {
            window.location.href = '#vision'
            window.location.href = '#Vision'

            // window.location.href = '#Weburl'

        }
        if (memecoinsection == 4) {
            window.location.href = '#token'
            window.location.href = '#Token'

            // window.location.href = '#Weburl'

        }
        if (memecoinsection == 5) {
            window.location.href = '#howtobuy'
            window.location.href = '#HowTOBuy'

            // window.location.href = '#Weburl'

        }

        if (memecoinsection == 6) {
            window.location.href = '#footer'
            window.location.href = '#Footer'

            // window.location.href = '#Weburl'

        }
    }, [memecoinsection])



    // console.log('section', memecoinsection)

    function hexToRgb(hex) {
        let bigint = parseInt(hex.slice(1), 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
    }

    function rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max == min) {
            h = s = 0; // achromatic
        } else {
            let d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }

        return [h, s, l];
    }

    function hslToRgb(h, s, l) {
        let r, g, b;

        if (s == 0) {
            r = g = b = l; // achromatic
        } else {
            function hue2rgb(p, q, t) {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            }

            let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            let p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    function darkenHexColor(hex, percent) {
        let [r, g, b] = hexToRgb(hex);
        let [h, s, l] = rgbToHsl(r, g, b);
        l = l - l * (percent / 100);
        [r, g, b] = hslToRgb(h, s, l);
        return `rgb(${r},${g},${b})`;
    }

    var darkenedColor = darkenHexColor(`${memetokenomicsbgcolor}`, 30); // Darken by 20%
    console.log(darkenedColor); // Output: rgb(0, 33, 204)
    useEffect(() => {
        settokenvalubgcolor(darkenedColor)
    }, [memetokenomicsbgcolor])
    console.log('Locations', window.location.href)

    useEffect(() => {
        setLocations(window.location.href)
    }, [])
    console.log('Locations', location);
    console.log('modalShow', modalShow, selectMemeCoinTemplates, showWebsite);
    const Step3test1 = step3.slice(0, 11);
    const Step3test2 = step3.slice(12,)
    console.log('step3', memecoinsection);

    useEffect(() => {
        if (selectMemeCoinTemplates == 2) {
            // setMemeherobgcolor('#7939c8')
            // setMemeherobackground(1);
            // setMemenavbarbgcolor('#7939c8');
            // setMemebackground(1);
            // setMemetokenomicsbgcolor('#7939c8');
            // setMemetokenomicsbackground(1);
            // setMemestepbgcolor('#981F27');
            // setMemestepbackground(1);
        }
    }, [selectMemeCoinTemplates]);


    console.log('memeherobgcolor', memeherobgcolor);
    const test = template2footerlogo.split('');
    const test1 = test.splice(3, 0, `${memecoinsymbol}`);
    const test2 = test.join('');
    console.log('template2footerlogo', test2);

    //    console.log('template2step1',template2step1.split(''));
    for (var i = 0; i < template2step4.length; i++) {
        console.log('template2step1', template2step4[i], i)
    };
    const step1Test1 = template2step1.slice(0, 21);
    const step1Test2 = template2step1.slice(22, 41);
    const step1Test3 = template2step1.slice(42, 84);
    const step1Test4 = template2step1.slice(85, 127);
    const step1Test5 = template2step1.slice(128, 165);
    const Step1Test6 = template2step1.slice(166, 203);
    const Step1Test7 = template2step1.slice(204,);


    const Template2opentelegram = () => {
        window.open(`${template2telegramlink}`)
    };

    const Template2opentwitter = () => {
        window.open(`${template2twitterlink}`)
    };

    const Opentemplate2uniswap = () => {
        // window.open(`${template2uniswap}`)
        window.open(`${template2uniswap}`)

    };
    const OpenTemplate2CMC = () => {
        window.open(`${template2cmc}`)
    };
    const OpenTemplate2Coingecko = () => {
        window.open(`${template2coingecko}`)
    }
    console.log('memeherobackground', memestepbackground);

    const OpenTemplate3telegram = () => {
        window.open(`${template3telegramlink}`)
    };

    const OpenTemplate3twitter = () => {
        window.open(`${template3twitterlink}`)
    };

    const OpenTemplate3Uniswap = () => {
        window.open(`${template3uniswaplink}`)
    };
    const OpenTemplate3Email = () => {
        const url = `https://mail.google.com/mail/?view=cm&fs=1&to=${template3email}&su=Hello&body=How%20are%20you%3F`
        window.open(url, '_blank');
    }

    console.log('template3stepsection2color', template3stepsection2color);

    const insertLineBreaks = (text, maxChars) => {
        const lines = [];
        for (let i = 0; i < text.length; i += maxChars) {
            lines.push(text.substring(i, i + maxChars));
        }
        return lines.join('\n');
    };

    const formattedText = insertLineBreaks(memetokencaption, 20);
    const Template2caption = insertLineBreaks(template2caption, 20);
    const Template2memecaption = insertLineBreaks(template2memecaption, 25);
    const Template2info = insertLineBreaks(template2info, 15);
    const MemecoinAbout = insertLineBreaks(memecoinAbout, 20);



    return (
        <>
            <HelmetProvider>

                {selectMemeCoinTemplates == 1 ?
                    <>
                        <div >
                            {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}


                            <div >

                                <nav id="navbar" className="mememenu-memecoin" style={{ backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : `url(${Backgroundimage})`}` : '', height: 'auto', width: "auto", objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : '' }}>
                                    <div className="mememenu-inner" style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: "row", }}>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</span>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</span>
                                        </div>
                                        <div class="mememenu-item logo">
                                            <picture>
                                                <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Boomer} style={{ width: '360px', height: '200px' }} alt="boomer" />
                                            </picture>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }}>
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[2]}</span>
                                        </div>
                                        <div style={{ width: "15%", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: "row", overflow: 'hidden' }} >
                                            <span class="mememenu-item" aria-label="boomer-link" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }} >{Navbar[3]}</span>
                                        </div>


                                    </div>
                                </nav>
                            </div>
                            <div class='' className="section" id="hero" style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${heroImage})` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "" }}>
                                <div className="section-inner">
                                    <div className="hero-animation">
                                        <div className="animation-wrapper">
                                            {memecoinAminitionImage.length > 0 ? <img src={memecoinAminitionImage} style={{ height: '400px', width: '350px', marginTop: 50, objectFit: 'cover', backgroundSize: 'cover' }}></img> : <img src={cartoon} style={{ height: '400px', width: '350px' }}></img>}
                                        </div>
                                    </div>
                                    <div class="header-container" style={{ backgroundImage: memecloudimage.length > 0 ? `url(${memecloudimage})` : `url(${CloudImage})` }} >
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} >{Caption1}</h2>
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} >{Caption2}</h2>


                                        <h1 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{memecoinValue}</h1>
                                        <h2 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>{memecoinname}</h2>
                                    </div>
                                    <div class="boomer-cartoon boomer-cig">
                                        <img src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : boomerright} style={{ width: '350px', height: "400px", backgroundSize: 'cover' }} alt="" />
                                    </div>
                                    <div class="boomer-cartoon boomer-chair">
                                        <img src={memecoinaboutleftimage.length > 0 ? memecoinaboutleftimage : boomerleft} style={{ width: '350px', height: "400px", backgroundSize: 'cover' }} alt="" />
                                    </div>
                                    <div class="memebutton-container">
                                        <a aria-label="boomer-link" href="" target="_blank" ><div class="memebutton memebutton-primary" style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} onClick={() => window.open(`${template2cmc}`)}>Buy ${memecoinsymbol}</div></a>
                                        <a aria-label="boomer-link" href="" target="_blank" ><div class="memebutton memebutton-secondary" style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }} onClick={() => window.open(`${memetelegramlink}`)}>{memeherobutton} <i class="fa-brands fa-telegram"></i></div></a>
                                    </div>
                                </div>
                                <div class="arrow-memecoin"><a href="#vision"><img width="100%" src={arrow} alt="continue to next section for more information" /></a></div>
                            </div>

                            <div class="section blue " id="vision" style={{ marginTop: '-0.5px', backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#0452ff' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover' }} >

                                <div class="section-inner" >
                                    <div class="header-container">
                                        <h1 style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>{memeabout}</h1>
                                        <p style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>{memecoinAbout}</p>

                                    </div>
                                    <div class="boomer-cartoon boomer-cig"  >
                                        {memecoinavisionrightimage.length > 0 ? <img src={memecoinavisionrightimage} style={{ width: '350px', marginTop: 200, height: '350px', backgroundSize: 'cover' }}></img> :
                                            <img src={aboutright} style={{ width: '350px', height: "400px" }} alt="" />}
                                    </div>
                                    <div class="boomer-cartoon boomer-chair" >
                                        {memecoinavisionleftimage.length > 0 ? <img src={memecoinavisionleftimage} style={{ width: '350px', marginTop: 200, height: '350px', backgroundSize: 'cover' }}></img> : <img src={aboutleft} style={{ width: '350px', height: "400px" }} alt="" />
                                        }
                                    </div>


                                </div>

                            </div>

                            <div id='token' class="section white" style={{ marginBottom: '2rem', marginTop: '-60px' }} >
                                <span style={{ position: 'absolute', alignItems: "center", left: '50%', top: '' }}>
                                    <div class="header-container">
                                        <h1 style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{template1tokenomics}</h1>
                                    </div>
                                </span>

                                <div class="section-inner" >

                                    <div className="frame-container" style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `url(${Tokonemoicsbgimage})` : '', width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : '', }} >

                                        <div class="token-grid">
                                            {newaddtokest == 1 ? (null) : (newaddtokest.map((item, index) => (
                                                <div key={index}>
                                                    <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                        {newiconsImage.length > 0 ? <img src={newiconsImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Contract} alt="" />}
                                                        <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{item.title}</div>
                                                    </div>
                                                    <div class="textarea" aria-label="wallet address container" readonly="" style={{ padding: "15px", fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, backgroundColor: 'transparent', fontSize: `${memetokenfontSize}px` }} >{item.value}</div>
                                                </div>
                                            )))}

                                            {iSremove1 ? null : <div >
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinContractImage.length > 0 ? <img src={memecoinContractImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Contract} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoincontractaddress.title}</div>
                                                </div>
                                                <div class="textarea" aria-label="wallet address container" readonly="" style={{ padding: "15px", fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, backgroundColor: 'transparent', fontSize: `${memetokenfontSize}px` }} >{memecoincontractaddress.value}</div>
                                            </div>}


                                            {iSremove2 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinDatabaseImage.length > 0 ? <img src={memecoinDatabaseImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Database} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }} >{memecoinInitialsupply.title}</div>
                                                </div>
                                                <span class="animate-number" style={{ textAlign: 'center', fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinInitialsupply.value}</span>
                                            </div>}


                                            {iSremove3 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinCirculatingImage.length > 0 ? <img src={memecoinCirculatingImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Circulating} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinCirculatingSupply.title}</div>
                                                </div>

                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinCirculatingSupply.value}</span>
                                            </div>}

                                            {iSremove4 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinBurnedImage.length > 0 ? <img src={memecoinBurnedImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Burned} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinSupplyBurned.title}</div>
                                                </div>
                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinSupplyBurned.value}</span>
                                            </div>}

                                            {iSremove5 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinLiquidityImage.length > 0 ? <img src={memecoinLiquidityImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Liquidity} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}> {memecoinLiquidity.title}</div>
                                                </div>

                                                <span class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{memecoinLiquidity.value}</span>
                                                {/* <span class="animate-number" style={{ margin: '0px', padding: "0px!important", color: `${memecointoketextcolor}`,fontSize:`${memetokenfontSize}px` }}>%</span> */}
                                            </div>}

                                            {iSremove6 ? null : <div>
                                                <div style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                                                    {memecoinRenouncedImage.length > 0 ? <img src={memecoinRenouncedImage} alt="" style={{ height: 95, width: 80 }} /> : <img src={Renounced} alt="" />}
                                                    <div style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{contractRenounced.title}</div>
                                                    <br />
                                                    <div class="animate-number" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` }}>{contractRenounced.value}</div>

                                                </div>

                                            </div>}



                                        </div>

                                        <div style={{ textAlign: "center" }}>
                                            <div class="animated-rotate" style={{ margin: "auto" }}>
                                                <img width="50%" src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : tokonmocismask} alt="illustration of boomer coin" />
                                                <p class="boomer-coin-text" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px`, }} >{memecoinname} {memetokencaption}</p>
                                                <span href="#howtobuy" class="memebutton memebutton-secondary" style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor}`, color: memecointoketextcolor == 'white' ? 'blue' : `${memecointoketextcolor}`, fontSize: `${memetokenfontSize}px` ,cursor:'pointer'}} onClick={() => window.open(`${template2cmc}`)}>{memetokenbuy} {memecoinsymbol}</span>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="divider"></div>
                            </div>

                            <div className="frame-parent" id="howtobuy" style={{ backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : '', height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : "" }}>

                                <h2 className="heading-3" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading}</h2>


                                <div className="parent">
                                    <div className="div"

                                    >
                                        <div className="group-parent">
                                            <div className="wrapper"

                                            >
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px`, }}>{stepnumber1}</div>
                                            </div>
                                            <img className="frame-item" alt="" src="Line 2.svg" />
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>

                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading1}</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                {step1}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent"

                                        >
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px`, }}>{stepnumber2}</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading2}</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{step2}</b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent">
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px`, }}>{stepnumber3}</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading3}</b>
                                            <b className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>
                                                {Step3test1} {memecoinsymbol} {Step3test2}
                                            </b>
                                        </div>
                                    </div>
                                    <div className="div">
                                        <div className="group-parent">
                                            <div className="wrapper">
                                                <div className="div1" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px`, }}>{stepnumber4}</div>
                                            </div>
                                        </div>
                                        <div className="step-1-parent-memecoin" style={{ background: `linear-gradient(130deg, ${statsbackgroundcolor != 'white' ? statsbackgroundcolor : '#023cba'}, #516faf)`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                            <b className="step-5-optional-container" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{stepheading4}</b>
                                            <b className="mint-mlon-eusk-container">
                                                <span className="mint-mlon-eusk-container1">
                                                    <p className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{step4} {memecoinname}</p>
                                                    <p className="install-a-chrome" style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor}`, fontSize: `${memestepfontSize}px` }}>{memecoinsymbol}</p>
                                                </span>
                                            </b>
                                        </div>
                                    </div>

                                </div>
                            </div>



                            <div className="footermeme" id="footer" style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#0452ff' : memefooterbgcolor : '', marginTop: '-6rem', overflow: 'hidden' }}>
                                <div className="container" >
                                    <span className={location != ('http://localhost:3000/') || ('https://trendifyweb.ai/') ? " Newwhite-logosvg-icon" : "white-logosvg-icon"} style={{ color: `${memefootertextcolor}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}` }}>${memecoinsymbol}</span>
                                    <div className={location != ('http://localhost:3000/') && ('https://trendifyweb.ai/') ? 'Newmeme-was-made' : "meme-was-made"} style={{ color: `${memefootertextcolor}`, fontFamily: `${memefootertextfont}`, fontSize: `${memefooterfontSize}px` }}>{memecoinsymbol}{memefooterabout}</div>
                                </div>
                                <div style={{ display: 'flex',flexDirection:"row",gap:"20px",justifyContent:"center",position:"absolute",right:"20px",top:"20px"}}>
                                <a href={memetwitterlink} target="_blank" style={{display:removemedia1==false?'block':'none',}}>
                                    <img  alt="" src={twitterimage.length > 0 ? twitterimage : twitter} width={40} height={40}/>
                                </a>
                                <a href={memetelegramlink} target="_blank" style={{display:removemedia2==false?'block':'none'}} >
                                    <img  alt="" src={telegraminage.length > 0 ? telegraminage : telegram}  width={40} height={40}/>
                                </a>
                                <a href={memeYoutubelink} target="_blank" style={{display:removemedia3==false?'block':'none'}}>
                                    <img  alt="" src={youtubeimage.length > 0 ? youtubeimage : youtube} width={40} height={40}/>
                                </a>
                                <a href={memetiktoklink} target="_blank" style={{display:removemedia5==false?'block':'none'}}>
                                    <img  alt="" src={tiktokimage.length > 0 ? tiktokimage : tiktok}  width={40} height={40}/>
                                </a>
                                <a href={memeinstagramlink} target="_blank" style={{display:removemedia4==false?'block':'none'}}>
                                    <img  alt="" src={instagramimage.length > 0 ? instagramimage : instagram}  width={40} height={40}/>
                                </a>
                                <a href={memewarpcastlink} target="_blank" style={{display:removemedia6==false?'block':'none'}}>
                                    <img  alt="" src={warpcastimage.length > 0 ? warpcastimage : warpcast} width={40} height={40} />
                                </a>
                                </div>
                            </div>
                        </div></> : null}

                {/* --------------------------------------T1-------------------------------------------- */}

                {selectMemeCoinTemplates == 2 ? (
                    <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <div id='Navbar' className={styles.navbar} style={{ backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : memenavbgcolor.length === 0 ? '#7b42cc' : '', }}>
                            <a href="#" style={{ color: 'white', textDecoration: 'none', fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[0]}</a>
                            <a href="#" style={{ color: 'white', textDecoration: 'none', fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[1]}</a>
                            <h1 style={{ color: 'white', fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{memecoinname}</h1>
                            <a href="#" style={{ color: 'white', textDecoration: 'none', fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[2]}</a>
                            <a href="#" style={{ color: 'white', textDecoration: 'none', fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px` }}>{Navbar[3]}</a>
                        </div>

                        <div id='Hero' className={styles.container} style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : memecoinaboutbgImage.length > 0 ? "" : "#7b42cc", backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                            <div className={styles.containerDiv1}>
                                <h1 style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}> {template1caption} ${memecoinsymbol}</h1>
                                <p style={{ color: `${memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : 'white'}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>  {template1Info}</p>
                                <div className={styles.telegrambutton}>
                                    <button style={{ marginRight: '10px', color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`,display:removemedia1==true?'none':null }} onClick={() => window.open(`${memetelegramlink}`)}>{telegramtext}</button>
                                    <button style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`,display:removemedia2==true?'none':null }} onClick={() => window.open(`${memetwitterlink}`)}>{twittertext}</button>
                                </div>
                            </div>
                            <div className={styles.containerDiv2}>
                                <img className="hero-image" src={memecoinaboutrightimage.length > 0 ? memecoinaboutrightimage : MainImage} alt="Molly Image" style={{ width: '100%', maxWidth: '400px', margin: '20px auto' }} />
                            </div>
                        </div>

                        <div id='Vision' className={styles.sectionred}
                            style={{
                                backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#8e3134' : memeaboutbgcolor : '',
                                backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '',
                                height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover'
                            }}

                        >
                            <div className={styles.sectionredDiv1}>
                                <h1 style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor}`, fontSize: `${memeaboutfontSize}px` }}>${memecoinsymbol}</h1>
                                <p style={{ fontFamily: `${memecoinvisionfont}`, color: `${memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : 'white'}`, fontSize: `${memeaboutfontSize}px`, overflow: 'hidden', width: '100%', height: '100%' }}>
                                    {memecoinAbout}
                                </p>
                            </div>
                            <div className={styles.sectionredDiv2}>
                                <img className={styles.heroimage} src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : Solly} alt="Molly Image" style={{ width: '100%', maxWidth: '400px', margin: '20px auto' }} />
                            </div>
                        </div>

                        <div id='Token' className={styles.tokenomics}
                            style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : memecointokonomicsbgimage.length === 0 ? '#7b42cc' : '', }}
                        >
                            <h2
                                style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                            >{template1tokenomics}</h2>
                            <p
                                style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                            >{template1tokenomicssub1}</p>
                            <div className={styles.stats} >
                                <div style={{ textAlign: 'center' }}>
                                    <img src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : Tokenomic1} alt="Supply Image" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                    <h3
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomisupply.label}</h3>
                                    <p
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomisupply.value}</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={template1tokenimage2.length > 0 ? template1tokenimage2 : Tokenomics2} alt="Contract Image" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                    <h3
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomicontract.label}</h3>
                                    <p
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomicontract.value}</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <img src={template1tokenimage3.length > 0 ? template1tokenimage3 : Tokenomics3} alt="Tax Image" style={{ width: '200px', height: '200px', marginBottom: '10px' }} />
                                    <h3
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomiTax.label}</h3>
                                    <p
                                        style={{ fontFamily: `${memecointokenfont}`, color: `${memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}
                                    >{template1tokenomiTax.value}</p>
                                </div>
                            </div>

                            <br />
                        </div>

                        <div id='HowTOBuy' className={styles.socials}
                            style={{ backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : '', backgroundSize: 'cover', objectFit: 'cover', backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : memestepbgimage.length === 0 ? "#8e3134" : "", padding: '50px' }}


                        >
                            <div  >
                                <h1
                                    style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px` }}

                                >{template1tokenomicssub2}</h1>
                                <p
                                    style={{ fontFamily: `${memecoinstepfont}`, color: `${memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'white'}`, fontSize: `${memestepfontSize}px`, paddingBottom: "30px" }}
                                >{template1tokenomicssub3}</p>
                            </div>
                            <div className={styles.socialsDiv1}>
                                <div className={styles.socialsDiv3} style={{
                                    backgroundColor: 'white', height: '100%', padding: '20px', borderRadius: '5px', background: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : 'white', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)',
                                    display:removemedia3==true?'none':null,
                                }}>
                                    <img src={stepimage1.length > 0 ? stepimage1 : ContractImage1} alt="Telegram" style={{ width: '100px', height: '100px', margin: '15px',cursor:'pointer' }} onClick={() => window.open(`${memetelegramlink}`)} />

                                </div>
                                <div className={styles.socialsDiv3} style={{
                                    height: '100%', padding: '20px', borderRadius: '5px', backgroundColor: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : 'white', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'
                                    ,display:removemedia4==true?'none':null,

                                }}>
                                    <img src={stepimage2.length > 0 ? stepimage2 : ContractImage2} alt="Telegram" style={{ width: '100px', height: '100px', margin: '15px',cursor:'pointer'  }} onClick={() => window.open(`${template2cmc}`)} />

                                </div>
                                <div className={styles.socialsDiv3} style={{
                                    height: '100%', padding: '20px', borderRadius: '5px', backgroundColor: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : 'white', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'
                                    ,display:removemedia5==true?'none':null,

                                }}>
                                    <img src={stepimage3.length > 0 ? stepimage3 : ContractImage3} alt="Telegram" style={{ width: '100px', height: '100px', margin: '15px',cursor:'pointer'  }} onClick={() => window.open(`${template2uniswap}`)} />

                                </div>
                            </div>
                            <div className={styles.socialsDiv2}
                                style={{
                                    color: `${memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#8e3134'}`, padding: '10px', fontSize: '1.1rem', marginTop: '20px', fontFamily: `${memecoinstepfont}`,
                                    backgroundColor: `${t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#ffffff'}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'
                                }}
                            >
                                {template1stepcontract} {template1stepAdd}
                            </div>
                        </div>

                        <div id='Footer' className={styles.footer}
                            style={{ padding: '40px 20px', backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#7b42cc' : memefooterbgcolor : '', }}

                        >
                            <div className={styles.footerdiv1}>
                                <p style={{ color: `${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : 'white'}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}`, fontSize: '20px' }}>{memecoinsymbol} </p>
                                <p style={{ color: `${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : 'white'}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}`, width: "100%", height: "100%", overflow: "hidden" }}>{template1footerabout}</p>
                                <p style={{ color: `${memefootertextcolor.length > 0 ? `${memefootertextcolor}` : 'white'}`, fontSize: `${memefooterfontSize}px`, fontFamily: `${memefootertextfont}` }}> {memecoinsymbol} {t1footer}</p>
                            </div>
                            <div className={styles.footerdiv2}>
                                <img src={template1footerImage.length > 0 ? template1footerImage : FooterIamge} alt="Logo" style={{ width: '50%', height: '100%', }} />
                            </div>
                        </div>


                    </div>
                ) : null}

                {/* ---------------------------------------------T2---------------------------------------------- */}

                {selectMemeCoinTemplates == 3 ? (
                    <>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <section id='Navbar'
                            style={{
                                backgroundColor:
                                    memeherobackground === 0
                                        ? memeherobgcolor.length === 0
                                            ? "#fe9c34"
                                            : memeherobgcolor
                                        : "",
                                backgroundImage:
                                    memeherobackground === 1
                                        ? memecoinaboutbgImage.length !== 0
                                            ? `url(${memecoinaboutbgImage})`
                                            : ""
                                        : `url(${Template2sectionbg})`,
                                objectFit: "cover",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                            }}
                        >
                            {/* Navbar */}
                            <article className="T2firstsection">
                                <aside
                                    className="T2navarticle"
                                    style={{
                                        backgroundImage:
                                            memebackground === 0
                                                ? memenavbgcolor.length > 0
                                                    ? `url(${memenavbgcolor})`
                                                    : ""
                                                : "",
                                        objectFit: "cover",
                                        backgroundSize: "cover",
                                        backgroundColor: memebackground === 1 ? memenavbarbgcolor : "",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                    }}
                                >
                                    <div className="T2nav">
                                        <aside className="T2logocontainer">
                                            <img
                                                className="T2logo"
                                                alt="logo"
                                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            />
                                            <div
                                                className="T2heading"
                                                style={{
                                                    fontFamily: memecoinanvfont,
                                                    color: memenavtextcolor,
                                                    fontSize: `${memenavfontsize}px`,
                                                }}
                                            >
                                                {memecoinname}
                                            </div>
                                        </aside>
                                        <ul className="T2menu">
                                            {Navbar.map((item, index) => (
                                                <li key={index} className="T2menuitem">
                                                    <a
                                                        href="#"
                                                        style={{
                                                            fontFamily: memecoinanvfont,
                                                            color: memenavtextcolor,
                                                            fontSize: `${memenavfontsize}px`,
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {item}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                        <aside className="T2icons">
                                            <img
                                                className="T2icon"
                                                alt="Link icon"
                                                src={telegraminage.length > 0 ? telegraminage : Template2telegram}
                                                onClick={() => window.open(template2telegramlink)}
                                                style={{ cursor: "pointer",display:removemedia1==true?'none':null }}
                                            />
                                            <img
                                                className="T2icon"
                                                alt="Link icon"
                                                src={twitterimage.length > 0 ? twitterimage : Template2twitter}
                                                onClick={() => window.open(template2twitterlink)}
                                                style={{ cursor: "pointer" ,display:removemedia2==true?'none':null}}
                                            />
                                        </aside>
                                    </div>
                                </aside>

                                {/* Main section */}
                                <aside id='Hero'>
                                    <main className="T2mainarticle">
                                        <aside className="T2mainleft">
                                            <div className="T2heading">
                                                <h1
                                                    className="T2heading1"
                                                    style={{
                                                        color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#fff",
                                                        fontFamily: memecoinaboutfont,
                                                        fontSize: `${memeMainfontsize}px`,
                                                    }}
                                                >
                                                    {memecoinsymbol}
                                                </h1>
                                                <h6
                                                    className="T2heading6"
                                                    style={{
                                                        color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#fff",
                                                        fontFamily: memecoinaboutfont,
                                                        fontSize: `${memeMainfontsize}px`,
                                                    }}
                                                >
                                                    {template2memecaption}
                                                </h6>
                                            </div>
                                            <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo} alt="" />
                                        </aside>
                                        <aside className="T2mainright">
                                            <div className="T2welcometext">
                                                <p className="T2welcometexttxtcontainer">
                                                    <span
                                                        style={{
                                                            color: memecoinabouttextcolor,
                                                            fontFamily: memecoinaboutfont,
                                                            fontSize: `${memeMainfontsize}px`,
                                                        }}
                                                    >
                                                        {template2caption}{" "}
                                                    </span>
                                                    <span
                                                        className="T2g"
                                                        style={{
                                                            color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#ffcd4e",
                                                            fontFamily: memecoinaboutfont,
                                                            fontSize: `${memeMainfontsize}px`,
                                                        }}
                                                    >
                                                        {memecoinsymbol}{" "}
                                                    </span>
                                                    <span
                                                        className="T2meme"
                                                        style={{
                                                            color: memecoinabouttextcolor,
                                                            fontFamily: memecoinaboutfont,
                                                            fontSize: `${memeMainfontsize}px`,
                                                        }}
                                                    >
                                                        {memecoinsymbol} {template2info}
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="T2btns">
                                                <button
                                                    className="T2link"
                                                    onClick={() => window.open(template2cmc)}
                                                    style={{ cursor: "pointer",display:removemedia3==true?'none':null  }}
                                                >
                                                    <a
                                                        href="#"
                                                        className="T2buylinktext"
                                                        style={{
                                                            color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#000",
                                                            fontFamily: memecoinaboutfont,
                                                            fontSize: `${memeMainfontsize}px`,
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {template2mainbutton1} {memecoinsymbol}
                                                    </a>
                                                </button>
                                                <button
                                                    className="T2link"
                                                    onClick={() => window.open(template2telegramlink)}
                                                    style={{ cursor: "pointer",display:removemedia4==true?'none':null }}
                                                >
                                                    <a
                                                        href="#"
                                                        className="T2buylinktext"
                                                        style={{
                                                            color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#000",
                                                            fontFamily: memecoinaboutfont,
                                                            fontSize: `${memeMainfontsize}px`,
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        {template2mainbutton2}
                                                    </a>
                                                </button>
                                            </div>
                                            <p
                                                className="T2contractlabel"
                                                style={{
                                                    color: memecoinabouttextcolor.length > 0 ? memecoinabouttextcolor : "#fff",
                                                    fontFamily: memecoinaboutfont,
                                                    fontSize: `${memeMainfontsize}px`,
                                                    marginTop: '20px',
                                                }}
                                            >
                                                {template2tokenAddress}
                                            </p>
                                            <div className="T2threebtn">
                                                {uniswapimage.length > 0 ? (
                                                    <img
                                                        src={uniswapimage}
                                                        height="50px"
                                                        width="100px"
                                                        alt=""
                                                        style={{ cursor: "pointer" ,display:removemedia5==true?'none':null }}
                                                        onClick={() => window.open(template2uniswap)}
                                                    />
                                                ) : (
                                                    <button
                                                        className="T2link"
                                                        id="T2link1"
                                                        style={{ backgroundColor: "#ff94f4", cursor: "pointer",display:removemedia5==true?'none':null  }}
                                                        onClick={() => window.open(template2uniswap)}
                                                    >
                                                        <a href="#" className="T2threelinks" style={{ color: "#fff", textDecoration: "none", }}>
                                                            <span>Uniswap</span>
                                                            <img src={Template2unicorn} alt="" />
                                                        </a>
                                                    </button>
                                                )}
                                                {cmcimage.length > 0 ? (
                                                    <img
                                                        src={cmcimage}
                                                        height="50px"
                                                        width="100px"
                                                        alt=""
                                                        style={{ cursor: "pointer",display:removemedia6==true?'none':null  }}
                                                        onClick={() => window.open(template2cmc)}
                                                    />
                                                ) : (
                                                    <button
                                                        className="T2link"
                                                        id="T2link2"
                                                        style={{ backgroundColor: "#2b6aff", cursor: "pointer",display:removemedia6==true?'none':null  }}
                                                        onClick={() => window.open(template2cmc)}
                                                    >
                                                        <a href="#" className="T2threelinks" style={{ color: "#fff", textDecoration: "none", }}>
                                                            <span>CMC</span>
                                                            <img src={Template2M} alt="" />
                                                        </a>
                                                    </button>
                                                )}
                                                {coingeckoimage.length > 0 ? (
                                                    <img
                                                        src={coingeckoimage}
                                                        height="50px"
                                                        width="100px"
                                                        alt=""
                                                        style={{ cursor: "pointer",display:removemedia7==true?'none':null  }}
                                                        onClick={() => window.open(template2coingecko)}
                                                    />
                                                ) : (
                                                    <button
                                                        className="T2link"
                                                        id="T2link3"
                                                        style={{ backgroundColor: "#8cc63f", cursor: "pointer",display:removemedia7==true?'none':null  }}
                                                        onClick={() => window.open(template2coingecko)}
                                                    >
                                                        <a href="#" className="T2threelinks" style={{ color: "#fff", textDecoration: "none", }}>
                                                            <span>CoinGecko</span>
                                                            <img src={Template2Forg} alt="" />
                                                        </a>
                                                    </button>
                                                )}
                                            </div>
                                        </aside>
                                    </main>
                                </aside>
                            </article>
                        </section>

                        <section id='Vision'
                            className="T2-section"
                            style={{
                                backgroundColor:
                                    memeaboutbackground === 0
                                        ? memeaboutbgcolor.length === 0
                                            ? '#fe9c34'
                                            : memeaboutbgcolor
                                        : '',
                                backgroundImage:
                                    memeaboutbackground === 1
                                        ? memeaboutbgimage.length !== 0
                                            ? `url(${memeaboutbgimage})`
                                            : ''
                                        : `url(${Template2sectionbg})`,
                                objectFit: 'cover',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}

                        >
                            <article className="T2secondsection" >
                                <main >
                                    <aside className="T2secondleft">
                                        <h1 className="T2heading1 T2headingabout" style={{
                                            fontFamily: memecoinvisionfont,
                                            color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#fff',
                                            fontSize: `${memeaboutfontSize}px`,

                                        }}
                                        >
                                            {memeabout}
                                        </h1>
                                        <div className="T2welcometext T2abouttext">
                                            <p className="T2welcometexttxtcontainer" style={{
                                                fontFamily: memecoinvisionfont,
                                                color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#ffcd4e',
                                                fontSize: `${memeaboutfontSize}px`,
                                                
                                            }}
                                            >
                                                {memecoinAbout} {memecoinsymbol}
                                            </p>
                                        </div>
                                        <div className="T2btns T2secondbtn">
                                            <button
                                                className="T2link"
                                                onClick={() => window.open(template2cmc)}
                                                style={{ cursor: 'pointer' ,display:removemedia3==true?'none':null}}
                                            >
                                                <span className="T2buylinktext" style={{
                                                    fontFamily: memecoinvisionfont,
                                                    color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : 'black',
                                                }}>
                                                    {template2mainbutton1} {memecoinsymbol}
                                                </span>
                                            </button>
                                            <img
                                                className="T2logoimg"
                                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                alt=""
                                                style={{ height: "60px", width: "60px" }}
                                            />
                                        </div>
                                    </aside>
                                    <aside className="T2secondright">
                                        <img
                                            className="T2logoimg"
                                            src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            alt=""
                                            height={500}
                                            width={500}
                                        />
                                    </aside>
                                </main>
                            </article>
                        </section>

                        <section id='Token'
                            style={{
                                backgroundColor:
                                    memetokenomicsbackground === 0
                                        ? memetokenomicsbgcolor.length === 0
                                            ? '#fe9c34'
                                            : memetokenomicsbgcolor
                                        : '',
                                backgroundImage:
                                    memetokenomicsbackground === 1
                                        ? memecointokonomicsbgimage.length !== 0
                                            ? `url(${memecointokonomicsbgimage})`
                                            : ''
                                        : `url(${Template2sectionbg})`,
                                objectFit: 'cover',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                        >
                            <article className="T2Tokenomicssection">
                                <div className="T2Tokenomics">
                                    <h1
                                        className="T2heading1"
                                        style={{
                                            fontFamily: memecointokenfont,
                                            color: memecointoketextcolor.length > 0 ? memecointoketextcolor : '#fff',
                                            fontSize: `${memetokenfontSize}px`,
                                        }}
                                    >
                                        {template2tokenheading}
                                    </h1>
                                    <img
                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                        height="60px"
                                        width="60px"
                                        alt=""
                                    />
                                </div>
                                <main>
                                    <aside className="T2Tokenomicsleft">
                                        <img
                                            src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            alt=""
                                            width="350px"
                                            height="500px"
                                        />
                                    </aside>
                                    <aside className="T2Tokenomicsright">
                                        <div className="T2heading3" style={{ color: '#fff' }}>
                                            <p
                                                className="T2supplystatement"
                                                style={{
                                                    fontFamily: memecointokenfont,
                                                    color: memecointoketextcolor.length > 0 ? memecointoketextcolor : '#fff',
                                                    fontSize: `${memetokenfontSize}px`,
                                                }}
                                            >
                                                {template2tokensupply} <br />
                                                {template2tokentatesupply}
                                            </p>

                                            <p>
                                                <span
                                                    className="T2supplystatement"
                                                    style={{
                                                        fontFamily: memecointokenfont,
                                                        color: memecointoketextcolor.length > 0 ? memecointoketextcolor : '#fff',
                                                        fontSize: `${memetokenfontSize}px`,
                                                    }}
                                                >
                                                    {template2tokentax}
                                                </span>
                                            </p>

                                            <p>
                                                <span
                                                    className="T2supplystatement"
                                                    style={{
                                                        fontFamily: memecointokenfont,
                                                        color: memecointoketextcolor.length > 0 ? memecointoketextcolor : '#fff',
                                                        fontSize: `${memetokenfontSize}px`,
                                                    }}
                                                >
                                                    {template2tokenlp}
                                                </span>
                                            </p>

                                            <p>
                                                <span
                                                    className="T2supplystatement"
                                                    style={{
                                                        fontFamily: memecointokenfont,
                                                        color: memecointoketextcolor.length > 0 ? memecointoketextcolor : '#fff',
                                                        fontSize: `${memetokenfontSize}px`,
                                                    }}
                                                >
                                                    {memecoinsymbol} {template2tokenMeme}
                                                </span>
                                            </p>
                                        </div>
                                        <div className="T2btns" id="T2Tokenomicsbtn">
                                            <button
                                                className="T2link"
                                                id="T2link2"
                                                onClick={() => window.open(template2telegramlink)}
                                                style={{ cursor: 'pointer',display:removemedia4==true?'none':null }}
                                            >
                                                <a href="#" className="T2threelinks" style={{ textDecoration: 'none', }}>
                                                    <span
                                                        style={{
                                                            fontFamily: memecointokenfont,
                                                            color: memecointoketextcolor !== 'white' ? memecointoketextcolor : 'black',
                                                            fontSize: `${memetokenfontSize}px`,

                                                        }}
                                                    >
                                                        {template2tokenbutton} {memecoinsymbol}
                                                    </span>
                                                    <img src={telegraminage.length > 0 ? telegraminage : Template2telegram} alt="" />
                                                </a>
                                            </button>
                                            <img
                                                src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                                height="60px"
                                                width="60px"
                                                alt=""
                                            />
                                        </div>
                                    </aside>
                                </main>
                            </article>
                        </section>

                        <section id='HowTOBuy'
                            style={{
                                backgroundColor:
                                    memestepbackground === 0
                                        ? memestepbgcolor.length === 0
                                            ? '#fe9c34'
                                            : memestepbgcolor
                                        : '',
                                backgroundImage:
                                    memestepbackground === 1
                                        ? memestepbgimage.length !== 0
                                            ? `url(${memestepbgimage})`
                                            : ''
                                        : `url(${Template2sectionbg})`,
                                objectFit: 'cover',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <article className="T2howtobuysection">
                                <aside className="T2howtobuycontent" >
                                    <div class='d-flex align-items-center justify-content-center gap-3'>
                                        <h1
                                            className="T2heading1"
                                            style={{
                                                fontFamily: memecoinstepfont,
                                                color: memecoinsteptextcolor,
                                                fontSize: `${memestepfontSize}px`,
                                            }}
                                        >
                                            {template2stepheading} {memecoinsymbol}
                                        </h1>
                                        <img
                                            src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                            height="60px"
                                            width="60px"
                                            alt=""
                                        />
                                    </div>

                                    <div
                                        className="T2welcometext T2howtobuytext"
                                        style={{
                                            fontFamily: memecoinstepfont,
                                            color: memecoinsteptextcolor,
                                            fontSize: `${memestepfontSize}px`,
                                        }}
                                    >
                                        <p>
                                            <span
                                                style={{
                                                    color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#ffcd4e',
                                                }}
                                            >
                                                {template2stepheading1}
                                            </span>
                                            {template2step1}
                                        </p>
                                        <p>
                                            <span
                                                style={{
                                                    color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#ffcd4e',
                                                }}
                                            >
                                                {template2stepheading2}
                                            </span>
                                            {template2step2}
                                        </p>
                                        <p>
                                            <span
                                                style={{
                                                    color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#ffcd4e',
                                                }}
                                            >
                                                {template2stepheading3}
                                            </span>
                                            {template2step3}
                                        </p>
                                        <p>
                                            <span
                                                style={{
                                                    color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#ffcd4e',
                                                }}
                                            >
                                                {template2stepheading4.concat(memecoinsymbol)}
                                            </span>
                                            {template2step4}
                                        </p>
                                    </div>
                                </aside>
                            </article>
                        </section>

                        <section id='Footer'
                            style={{
                                backgroundImage:
                                    memefootrbackground === 1
                                        ? memefooterbgimage.length !== 0
                                            ? `url(${memefooterbgimage})`
                                            : ''
                                        : '',
                                backgroundRepeat: 'no-repeat',
                                objectFit: 'cover',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundColor:
                                    memefootrbackground === 0
                                        ? memefooterbgcolor.length === 0
                                            ? '#fe9c34'
                                            : memefooterbgcolor
                                        : '',
                            }}
                        >
                            <article className="T2footersection">
                                <aside>
                                    <img
                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template2Logo}
                                        width="200px"
                                        height="200px"
                                        className="T2centerimg"
                                        alt=""
                                    />
                                </aside>
                                <aside>
                                    <div className="T2footerheading" style={{ color: '#fff' }}>
                                        <p
                                            style={{
                                                color: memefootertextcolor,
                                                fontFamily: memefootertextfont,
                                                fontSize: `${memefooterfontSize}px`,
                                            }}
                                        >
                                            {template2footeringo}
                                        </p>
                                        <p
                                            className="T2text2"
                                            style={{
                                                color: memefootertextcolor,
                                                fontFamily: memefootertextfont,
                                                fontSize: `${memefooterfontSize}px`,
                                            }}
                                        >
                                            {test2}
                                        </p>
                                    </div>
                                </aside>
                            </article>
                        </section>







                    </>
                ) : null}

                {/* --------------------------------------------t3-------------------------------------------------- */}

                {selectMemeCoinTemplates == 4 ? (
                    <div  style={{overflowX: 'hidden'}}>
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <section
                            style={{
                                backgroundImage: memeherobackground === 0
                                    ? memecoinaboutbgImage.length > 0
                                        ? `url(${memecoinaboutbgImage})`
                                        : `url(${Template3BG})`
                                    : "",
                                backgroundColor: memeherobackground === 1 ? memeherobgcolor : "",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                backgroundRepeat: "no-repeat",
                            }}
                        >
                            <article id="navbar" className="T3-main-page">
                                <aside className="T3-first-aside-btn">
                                    <button
                                        className="T3-media-btn"
                                        style={{
                                            fontFamily: memecoinanvfont.length > 0 ? memecoinanvfont : "Finger Paint",
                                            color: memenavtextcolor,
                                            backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : 'white', objectFit: 'cover', backgroundSize: 'cover', backgroundPosition: 'center',
                                        }}

                                    >
                                        {Navbar[0]}
                                    </button>
                                    <button
                                        className="T3-media-btn"
                                        style={{
                                            fontFamily: memecoinanvfont.length > 0 ? memecoinanvfont : "Finger Paint",
                                            color: memenavtextcolor,
                                            backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : 'white', objectFit: 'cover', backgroundSize: 'cover', backgroundPosition: 'center',
                                        }}
                                    >
                                        {Navbar[1]}
                                    </button>
                                </aside>
                                <aside className="T3-cartoonparent">
                                    <img
                                        src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : Template3Sunppd}
                                        alt=""
                                    />
                                </aside>
                            </article>
                        </section>
                        
                        <section
                            style={{
                                backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#07060d' : memeaboutbgcolor : '',
                                backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '',
                                height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover'
                            }}
                        >
                            <article id="vision" className="T3-second-section">
                                <aside className="T3-first-aside">
                                    <div className="T3-welgome"
                                      style={{
                                        backgroundColor: statsbackgroundcolorT3,
                                        border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor}`,
                                        borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                        boxShadow: statsshowdowrange
                                            ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                            : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                      }}
                                    >
                                        <p
                                            className="T3-welgome-do-da"
                                            style={{
                                                fontFamily: memecoinvisionfont.length > 0 ? memecoinvisionfont : "Finger Paint",
                                                color: memecoinvisiontextcolor,
                                                
                                            }}
                                            
                                        >
                                            {template3heading}
                                        </p>
                                    </div>
                                    <p
                                        className="T3-innovadion"
                                        style={{
                                            fontFamily: memecoinvisionfont.length > 0 ? memecoinvisionfont : "Finger Paint",
                                            color: memecoinvisiontextcolor,
                                        }}
                                    >
                                        {memecoinAbout}
                                    </p>
                                    <div className="T3-memecoin">
                                        <img
                                            src={
                                                memecoinavisionleftimage.length > 0
                                                    ? memecoinavisionleftimage
                                                    : Template3Maks
                                            }
                                            alt=""
                                        />
                                    </div>
                                </aside>
                            </article>
                        </section>

                        < section 
                            style={{
                                backgroundImage:
                                    memetokenomicsbackground === 0 && memecointokonomicsbgimage.length > 0
                                        ? `url(${memecointokonomicsbgimage})`
                                        : "",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                backgroundSize: "cover",
                                backgroundColor:
                                    memetokenomicsbackground === 1
                                        ? memetokenomicsbgcolor
                                        : memecointokonomicsbgimage.length === 0
                                            ? "#07060d"
                                            : "",
                            }}
                        >
                            <article   id='token'  className="T3-third-section">
                                <aside className="T3-top-aside">
                                    
                                     
                                    
                                        <div
                                            className="T3-ghoose-child"
                                            style={{
                                                fontFamily: memecointokenfont.length > 0 ? memecointokenfont : "Finger Paint",
                                                color: memecointoketextcolor,
                                                backgroundColor: statsbackgroundcolorT3,
                                                border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor }`,
                                                borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                                boxShadow: statsshowdowrange
                                                    ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                    : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            }}
                                            
                                            
                                        >
                                           {tokenheadingt3} {memecoinname}?
                                        </div>
                                    
                                </aside>
                                <aside className="T3-bottom-aside">
                                    <div className="T3-four-cards-parent">
                                        {[template3section1, template3Section2, template3Section3, template3Section4].map(
                                            (content, index) => (
                                                <div
                                                    key={index}
                                                    className="T3-four-card"
                                                    style={{
                                                        fontFamily:
                                                            memecointokenfont.length > 0 ? memecointokenfont : "Finger Paint",
                                                        color: memecointoketextcolor,
                                                        fontSize: `${memetokenfontSize}px`,
                                                        backgroundColor: statsbackgroundcolorT3,
                                                        border: `${statsborder.length > 0 ? statsborder : "10"}px solid ${statsbordercolor.length > 0 ? statsbordercolor : "white"
                                                            }`,
                                                        borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "30"
                                                            }px`,
                                                        boxShadow: statsshowdowrange
                                                            ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21)`
                                                            : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                                    }}
                                                >
                                                    <p className="T3-four-card-heafding-text" style={{fontFamily:
                                                            memecointokenfont.length > 0 ? memecointokenfont : "Finger Paint",
                                                        color: memecointoketextcolor,}}>
                                                        
                                                        {[tokenheading1T3,tokenheading2T3, tokenheading3T3, tokenheading4T3][
                                                            index
                                                        ]}
                                                    </p>
                                                    <p className="T3-four-card-para" style={{fontFamily:
                                                            memecointokenfont.length > 0 ? memecointokenfont : "Finger Paint",
                                                        color: memecointoketextcolor,}}>
                                                        {content} {index === 0 && memecoinsymbol}
                                                    </p>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </aside>
                            </article>
                        </section>

                        <div  style={{
                                backgroundImage: memestepbackgroundt3 === 0 ? `url(${template3stepsection2bg})` : "",
                                height: "100%",
                                width: "100%",
                                backgroundSize: "cover",
                                objectFit: "cover",
                                backgroundColor:
                                    memestepbackgroundt3 === 1
                                        ? template3stepsection2color
                                        : template3stepsection2bg.length === 0
                                            ? "#834d3b"
                                            : "",
                                            padding:'40px',
                                
                            }}>
                        <section
                               id='HowTOBuy'
                              >
                            <article  className="T3-fourth-section">
                                <aside className="T3-Fourth-first-aside">
                                    <div className="T3-snupped-image">
                                        <img
                                            src={memecoinlogoImage.length > 0 ? memecoinlogoImage : Template3SectionSnuppd}
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className="T3-snupped-content"
                                       
                                    >
                                        <h1 className="T3-snupped-heading"  style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : "Finger Paint",
                                            color: memecoinsteptextcolor,
                                            fontSize: `${memestepfontSize}px`,
                                        }}>
                                            {template3stepquestion} {memecoinsymbol}?
                                        </h1>
                                        <p className="T3-snupped-para"  style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : "Finger Paint",
                                            color: memecoinsteptextcolor,
                                            fontSize: `${memestepfontSize}px`,
                                        }}>
                                            {memecoinname} {template3stepsection1}
                                        </p>
                                    </div>
                                </aside>
                                <aside className="T3-Fourth-second-aside"></aside>
                            </article>
                        </section>
                        <section
                            
                        >
                            <article className="T3-fifth-section" class='d-flex'>
                                <aside className="T3-fifth-first-aside" class='d-flex'>
                                    <div
                                        className="T3-dog-content"
                                       
                                    >
                                        <h1 className="T3-snupped-heading"  style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : "Finger Paint",
                                            color: memecoinsteptextcolor,
                                            fontSize: `${memestepfontSize}px`,
                                        }}>{template3step3section2heading}</h1>
                                        <p className="T3-snupped-para"  style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : "Finger Paint",
                                            color: memecoinsteptextcolor,
                                            fontSize: `${memestepfontSize}px`,
                                        }}>{template3stepsection2}</p>
                                    </div>
                                    <div className="T3-dog-image">
                                        <img
                                            src={
                                                template3stepimage.length > 0
                                                    ? template3stepimage
                                                    : Template3DogImage
                                            }
                                            alt="Step Section Dog"
                                        />
                                    </div>
                                </aside>
                            </article>
                        </section>
                        </div>


                        <section  
                            className="T3-footer-section"
                            style={{
                                backgroundImage:
                                    memefootrbackground === 1 && memefooterbgimage.length !== 0
                                        ? `url(${memefooterbgimage})`
                                        : memefootrbackground === 0 && memefooterbgcolor.length === 0
                                            ? `url(${Template3BG})`
                                            : "",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundColor:
                                    memefootrbackground === 0
                                        ? memefooterbgcolor.length > 0
                                            ? memefooterbgcolor
                                            : ""
                                        : "",
                            }}
                        >
                            <article id="footer" className="T3-sixth-section">
                                <aside className="T3-button-container">
                                    <button
                                        className="T3-btn "
                                        style={{
                                            backgroundColor: statsbackgroundcolorT3,
                                            border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                                }`,
                                            borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                            boxShadow: statsshowdowrange
                                                ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            color: memefootertextcolor,
                                            fontFamily: memefootertextfont,
                                        }}
                                    >
                                        {template3footerheading}
                                    </button>
                                </aside>
                                <aside className="T3-paragraph-container">
                                    <p
                                        className="T3-footerpara"
                                        style={{
                                            backgroundColor: statsbackgroundcolorT3,
                                            border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                                }`,
                                            borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                            boxShadow: statsshowdowrange
                                                ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            color: memefootertextcolor,
                                            fontFamily: memefootertextfont,
                                        }}
                                    >
                                        {template3footerinfo}
                                    </p>
                                </aside>
                                <aside className="T3-media-links">

                                    <div className="T3-media-link" style={{display:removemedia1==true?"none":"block"}}>
                                        <button
                                            className="T3-media-btn"
                                            style={{
                                                color: memefootertextcolor.length > 0 ? memefootertextcolor : "white",
                                                fontFamily: memefootertextfont.length > 0 ? memefootertextfont : "Finger Paint",
                                                fontSize: `${memefooterfontSize}px`,
                                                backgroundColor: statsbackgroundcolorT3!='#834d3b'?statsbackgroundcolorT3:"white",
                                                border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                                    }`,
                                                borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                                boxShadow: statsshowdowrange
                                                    ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                    : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            }}
                                            onClick={()=>window.open(`${template3twitterlink}`)}
                                        >
                                            {twittertextt4}
                                        </button>
                                    
                                    </div>
                                    <div className="T3-media-link" style={{display:removemedia2==true?"none":"block"}}>
                                        <button
                                            className="T3-media-btn"
                                            style={{
                                                color: memefootertextcolor.length > 0 ? memefootertextcolor : "white",
                                                fontFamily: memefootertextfont.length > 0 ? memefootertextfont : "Finger Paint",
                                                fontSize: `${memefooterfontSize}px`,
                                                backgroundColor: statsbackgroundcolorT3!='#834d3b'?statsbackgroundcolorT3:"white",
                                                border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                                    }`,
                                                borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                                boxShadow: statsshowdowrange
                                                    ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                    : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            }}
                                            onClick={()=>window.open(`${template3telegramlink}`)}
                                        >
                                            {telegramtext}
                                        </button>
                                    
                                    </div>
                                    <div className="T3-media-link" style={{display:removemedia3==true?"none":"block"}}>
                                        <button
                                            className="T3-media-btn"
                                            style={{
                                                color: memefootertextcolor.length > 0 ? memefootertextcolor : "white",
                                                fontFamily: memefootertextfont.length > 0 ? memefootertextfont : "Finger Paint",
                                                fontSize: `${memefooterfontSize}px`,
                                                backgroundColor: statsbackgroundcolorT3!='#834d3b'?statsbackgroundcolorT3:"white",
                                                border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                                    }`,
                                                borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                                boxShadow: statsshowdowrange
                                                    ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                                    : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                            }}
                                            onClick={() => window.open(`${template3uniswaplink}`)}
                                        >
                                            {Dextoolstext}
                                        </button>
                                    
                                    </div>
                                     
                                </aside>
                                <aside className="T3-image-container">
                                    <img
                                        style={{ width: "600px", height: "600px" }}
                                        className="T3-footer-image"
                                        src={
                                            template1footerImage.length > 0 ? template1footerImage : Template3FooterSunppd
                                        }
                                        alt="Footer Graphic"
                                    />
                                </aside>
                                <div
                                    className="T3-footer-text"
                                    style={{
                                        backgroundColor: statsbackgroundcolorT3,
                                        border: `${statsborder.length > 0 ? statsborder : "8"}px solid ${statsbordercolor !== "white" ? statsbordercolor : "black"
                                            }`,
                                        borderRadius: `${statsborderradius.length > 0 ? statsborderradius : "10"}px`,
                                        boxShadow: statsshowdowrange
                                            ? `0px 0px ${statsshowdowrange}px rgba(21, 21, 25, 0.21)`
                                            : "0px 19px 30px 8px rgba(21, 21, 25, 0.21)",
                                        color: memefootertextcolor,
                                        fontFamily: memefootertextfont,
                                    }}
                                >
                                    {template3memeaddress}
                                </div>
                            </article>
                        </section>




                    </div>
                ) : null}


                {/* -----------------------------------------------t4----------------------------------------------------- */}
                {selectMemeCoinTemplates == 6 ? <div style={{ position: 'relative', height: "100%", width: "100%", }}>
                    {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}
                    <section>
                        <nav id='Navbar' style={{ position: 'relative', backgroundImage: memebackground == 0 ? `${memenavbgcolor.length > 0 ? `url(${memenavbgcolor})` : ``}` : '', backgroundColor: memebackground == 1 ? `${memenavbarbgcolor}` : '', objectFit: 'cover', backgroundSize: 'cover', backgroundPosition: 'center', }}>
                            <aside className="T6logo">
                                <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo} alt="logo" />
                            </aside>
                            <aside className="T6links">
                                <ul>
                                    {Navbar.map((item, index) => (
                                        <li key={index}>
                                            <a href="#" style={{ fontFamily: `${memecoinanvfont}`, color: `${memenavtextcolor}`, fontSize: `${memenavfontsize}px`, overflowWrap: "anywhere" }}>
                                                {item}
                                            </a>
                                        </li>
                                    ))}
                                    <button className="T6buy-permit" onClick={() => window.open(`${template2cmc}`)} style={{ fontFamily: memecoinanvfont, color: memenavtextcolor, fontSize: `${memenavfontsize}px`, background: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#d93731', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5em', boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}>
                                        {t4navbutton} {memecoinsymbol}
                                    </button>
                                </ul>
                            </aside>
                        </nav>
                    </section>

                    <section
                        className="T6main-section"
                        style={{
                            backgroundImage: `url(${memecoinaboutbgImage.length > 0 ? memecoinaboutbgImage : T4MainSectionImage})`,
                        }}
                    >
                        <main id='Hero' style={{ backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${T4MainSectionImage})` : "", backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', }}>
                            <article className="T6common-article main-article">
                                <aside className="T6main-left-side">
                                    <h1 style={{ color: memecoinabouttextcolor, fontFamily: memecoinaboutfont, fontSize: `${memeMainfontsize}px`, overflowWrap: 'anywhere' }}>
                                        {t4caption}
                                    </h1>
                                    <p style={{ color: memecoinabouttextcolor, fontFamily: memecoinaboutfont, fontSize: `${memeMainfontsize}px` }}>
                                        {t4memeaddress}
                                    </p>
                                    <div className="T6buy-permit-Parent">
                                        <button
                                            className="T6buy-permit buy-pc"
                                            onClick={() => window.open(`${template2cmc}`)}
                                            style={{
                                                color: memecoinabouttextcolor, fontFamily: memecoinaboutfont, fontSize: `${memeMainfontsize}px`,
                                                background: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#d93731', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5em', boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'
                                            }}
                                        >
                                            {t4mainbutton1} {memecoinsymbol}
                                        </button>
                                        <button
                                            className="T6buy-permit T6buy-pc"
                                            id="T6PREMT-ON-X"
                                            onClick={() => window.open(`${memetwitterlink}`)}
                                            style={{
                                                color: memecoinabouttextcolor, fontFamily: memecoinaboutfont, fontSize: `${memeMainfontsize}px`,
                                                background: t4statsbackgrounsd.length > 0 ? t4statsbackgrounsd : '#254a9a', border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: statsborderradius.length > 0 ? `${statsborderradius}px` : '0.5em', boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)',

                                            }}
                                        >
                                            {memecoinsymbol} {t4mainbutton2}
                                        </button>
                                    </div>
                                </aside>
                                <aside className="T6main-right-side">
                                    <img
                                        src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T4Memelogo}
                                        alt="Memecoin Logo"
                                        height={400}
                                        width={400}
                                    />
                                </aside>
                            </article>
                        </main>
                    </section>

                    <section
                        className="T6common-section"
                        style={{
                            backgroundColor: memeaboutbackground === 0 ? (memeaboutbgcolor.length === 0 ? 'white' : memeaboutbgcolor) : '',
                            backgroundImage: memeaboutbackground === 1 ? (memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '') : '',
                            objectFit: 'cover',
                            backgroundSize: 'cover',
                        }}
                    >
                        <article id='Vision' className="T6common-article doland-tremp-parent">
                            <aside className="T6doland-tremp-left">
                                <h1 style={{ color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#254a9a', fontFamily: memecoinvisionfont, overflowWrap: 'anywhere' }}>
                                    {memecoinname}
                                </h1>
                                <p style={{ color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#0b0f3a', fontFamily: memecoinvisionfont, overflowWrap: 'anywhere' }}>
                                    {memecoinAbout}
                                </p>
                                <p className="T6premt-is-a" style={{ color: memecoinvisiontextcolor.length > 0 ? memecoinvisiontextcolor : '#0b0f3a', fontFamily: memecoinvisionfont, overflowWrap: 'anywhere' }}>
                                    {memecoinsymbol}
                                </p>
                                <div className="T6social-media">
                                    <div className="T6social-icon" style={{display:removemedia1==true?'none':'block'}}>
                                        <a href="#">
                                            <img
                                                src={twitterimage.length > 0 ? twitterimage : T4twitter}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => window.open(memetwitterlink)}
                                                alt="Twitter"
                                            />
                                        </a>
                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia2==true?'none':'block'}}>
                                        <a href="#">
                                            <img
                                                src={telegraminage.length > 0 ? telegraminage : T4telegram}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => window.open(memetelegramlink)}
                                                alt="Telegram"
                                            />
                                        </a>
                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia3==true?'none':'block'}}>
                                        <a href="#">
                                            <img
                                                src={instagramimage.length > 0 ? instagramimage : T4Instagram}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => window.open(memeinstagramlink)}
                                                alt="Instagram"
                                            />
                                        </a>
                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia4==true?'none':'block'}}>
                                        <a href="#">
                                            <img
                                                src={warpcastimage.length > 0 ? warpcastimage : T4trend}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => window.open(dexscannerlink)}
                                                alt="Warpcast"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </aside>
                            <aside className="T6doland-tremp-right">
                                <img
                                    src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : T4MemeImage1}
                                    alt="Vision"
                                />
                            </aside>
                        </article>
                    </section>

                    <section
                    id='HowTOBuy'
                        style={{

                            backgroundImage: memestepbackground == 1 ? `url(${memestepbgimage})` : '',
                            backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center',
                            backgroundColor: memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#254a9a' : `${memestepbgcolor}` : ""

                        }}
                    >
                        <article  className="T6common-article How-to-buystart">
                            <aside className="T6how-to-buystart-left">
                                <h1
                                    style={{
                                        fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                        color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {t4step1}
                                </h1>
                                <img src={t4stepimage.length > 0 ? t4stepimage : T4MemeImage2} alt="Doland Tremp" />
                            </aside>
                            <aside className="T6how-to-buystart-right">
                                <div className="T6buystart-right-child">
                                    <h1
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4stepheading1}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere'
                                        }}
                                    >
                                        {t4step2}
                                    </p>
                                </div>
                                <div className="T6buystart-right-child last-three">
                                    <h1
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4stepheading2}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4step3}
                                    </p>
                                </div>

                                <div className="T6buystart-right-child last-three">
                                    <h1
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4stepheading3}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4step4}
                                    </p>
                                </div>
                                <div className="T6buystart-right-child last-three">
                                    <h1
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4stepheading4}
                                    </h1>
                                    <p
                                        style={{
                                            fontFamily: memecoinstepfont.length > 0 ? memecoinstepfont : 'Inter',
                                            color: memecoinsteptextcolor.length > 0 ? memecoinsteptextcolor : '#fff',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {t4step5}
                                    </p>
                                </div>
                            </aside>
                        </article>
                    </section>

                    <section

                    >
                        <article id='Token' className="T6memes"
                            style={{ backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : '', backgroundRepeat: 'no-repeat' }}

                        >
                            <div
                                className="T6memesh1 "
                                style={{
                                    fontFamily: memecointokenfont.length > 0 ? memecointokenfont : 'Inter',
                                    color: memecointoketextcolor !== 'white' ? memecointoketextcolor : '#0B0F3A',
                                    overflowWrap: 'anywhere',
                                }}
                            >
                                {t4token}
                            </div>

                            <img
                                src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T4MemeImage3}
                                alt="T6Tokenomics"
                            />
                        </article>
                    </section>
                    {/* <section
                        className="T6in-the-news-parent"
                        style={{
                            backgroundImage: memefootrbackground === 1 ? (memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '') : '',
                            objectFit: 'cover',
                            backgroundSize: 'cover',
                            backgroundColor: memefootrbackground === 0 ? (memefooterbgcolor.length === 0 ? '#0b0f3a' : memefooterbgcolor) : '',
                        }}
                    >
                        <article className="T6in-the-news">
                            <div
                             className="T6in-the-newsh1"
                                style={{
                                    color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                    fontFamily: memefootertextfont.length > 0 ? memefootertextfont : 'Inter',
                                }}
                            >
                                {t4footer4}
                            </div>
                            <aside>
                                <div
                                    className="T6news-content"
                                    style={{
                                        color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                        fontFamily: memefootertextfont.length > 0 ? memefootertextfont : 'Inter',
                                    }}
                                >
                                    {t4footer1}
                                </div>
                                <div
                                    className="T6news-content"
                                    style={{
                                        color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                        fontFamily: memefootertextfont.length > 0 ? memefootertextfont : 'Inter',
                                    }}
                                >
                                    {t4footer2}
                                </div>
                                <div
                                    className="T6news-content"
                                    style={{
                                        color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                        fontFamily: memefootertextfont.length > 0 ? memefootertextfont : 'Inter',
                                    }}
                                >
                                    {t4footer3}
                                </div>
                            </aside>
                        </article>
                    </section> */}

                    <section id='Footer' className="T6footer" style={{

                        display: 'flex',
                        flexDirection: 'column',
                        backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', backgroundPosition: 'center', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#254a9a' : memefooterbgcolor : '',
                        backgroundRepeat: 'no-repeat',
                    }}>
                        <article
                            className="T6common-article T6footer-article"
                            style={{ borderBottom: '1px solid #fff' }}
                        >
                            <aside className="T6footer-left">
                                <div className="T6logo">
                                    <img
                                        src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo}
                                        alt=""
                                    />
                                </div>
                            </aside>
                            <aside className="T6footer-right">
                                <div className="T6social-media">
                                    <div className="T6social-icon" style={{display:removemedia1==true?'none':'block'}}>

                                        <img
                                            src={telegraminage.length > 0 ? telegraminage : T4footertelegram}
                                            style={{ cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => window.open(memetelegramlink)}
                                        />

                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia2==true?'none':'block'}}>

                                        <img
                                            src={twitterimage.length > 0 ? twitterimage : T4footerTwitter}
                                            style={{ cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => window.open(memetwitterlink)}
                                        />

                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia3==true?'none':'block'}}>

                                        <img
                                            src={instagramimage.length > 0 ? instagramimage : T4footerInstagram}
                                            style={{ cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => window.open(memeinstagramlink)}
                                        />

                                    </div>
                                    <div className="T6social-icon" style={{display:removemedia4==true?'none':'block'}}>

                                        <img
                                            src={warpcastimage.length > 0 ? warpcastimage : T4footertrend}
                                            style={{ cursor: 'pointer' }}
                                            alt=""
                                            onClick={() => window.open(dexscannerlink)}
                                        />

                                    </div>
                                </div>
                                <p
                                    style={{
                                        color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                        fontFamily: memefootertextfont,
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {t4footer1}
                                </p>
                            </aside>
                        </article>
                        <p
                            className="T6All-rights-reserved"
                            style={{
                                color: memefootertextcolor.length > 0 ? memefootertextcolor : 'white',
                                fontFamily: memefootertextfont,
                                overflowWrap: 'anywhere',
                            }}
                        >
                            {t4footer2} <br />
                            <span style={{ overflowWrap: 'anywhere', }}>{t4footer3}</span>
                        </p>
                    </section>

                    {/* <div
                        style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",

                            backgroundImage: memeherobackground == 0 ? memecoinaboutbgImage.length > 0 ? `url(${memecoinaboutbgImage})` : `url(${T4MainSectionImage})` : "",

                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                        }}
                    >
                        <div style={{
                            backgroundColor: "#254a9a", opacity: '0.9', position: "relative",
                            backgroundColor: memeherobackground == 1 ? `${memeherobgcolor}` : "#254a9a",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            <div style={{ color: "white", width: '50%', display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "center", marginLeft: "50px", overflow: 'hidden' }}>
                                <div className={styleT4.mekMemesGretContainer} style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px` }}>

                                    {t4caption}
                                </div>
                                <div style={{ color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`, lineBreak: 'anywhere' }}>
                                    {t4memeaddress}
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: '20px', marginTop: "30px" }}>
                                    <button
                                        style={{
                                            width: '150px',
                                            position: 'relative',
                                            borderRadius: '6.4px',
                                            backgroundColor: '#d93731',
                                            height: '69.2px',
                                            textAling: 'center',
                                            fontSize: '16px',
                                            color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#fff',
                                            fontFamily: 'Inter',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: `${memecoinabouttextcolor}`, fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`,
                                        }} onClick={() => window.open('https://app.uniswap.org/')}>{t4mainbutton1}</button>
                                    <button
                                        style={{
                                            width: '150px',
                                            position: 'relative',
                                            borderRadius: '6.4px',
                                            backgroundColor: 'blue',
                                            height: '69.2px',
                                            textAling: 'center',
                                            fontSize: '16px',
                                            color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#fff',
                                            fontFamily: 'Inter',
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            opacity: '1',
                                            fontFamily: `${memecoinaboutfont}`, fontSize: `${memeMainfontsize}px`
                                        }} onClick={() => window.open(`${memetwitterlink}`)}>{t4mainbutton2}</button>
                                </div>
                            </div>
                            <div style={{ color: "white", width: '50%', display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T4Memelogo} alt="logo" height={'100%'}></img>
                            </div>
                        </div>

                    </div>

                    <div
                        style={{
                            position: "relative",
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            padding: "50px",
                            backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? 'white' : memeaboutbgcolor : '', backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover'
                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                height: "100%",
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                                gap: "20px"
                            }}
                        >
                            <div
                                style={{

                                    textTransform: 'uppercase',
                                    fontWeight: '800',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textAling: 'left',
                                    display: 'flex',
                                    alignItem: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    width: "70%"

                                }}
                            >{memecoinname}</div>
                            <div
                                style={{
                                    width: '95%',
                                    position: 'relative',
                                    fontSize: '14.5px',
                                    letterSpacing: '-0.19px',
                                    lineHeight: '29.18px',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textALing: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'fit-content',
                                    marginTop: '10px',
                                    overflow: 'hidden',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    lineBreak: 'anywhere'
                                }}
                            >
                                {memecoinAbout}
                            </div>
                            <div
                                style={{
                                    width: '179.7px',
                                    position: 'relative',
                                    fontSize: '19.5px',
                                    letterSpacing: '-0.19px',
                                    lineHeight: '29.18px',
                                    fontFamily: 'Inter',
                                    color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#254a9a',
                                    textAling: 'left',
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    fontFamily: `${memecoinvisionfont}`, fontSize: `${memeaboutfontSize}px`,
                                    lineBreak: 'anywhere',
                                }}
                            >${memecoinsymbol}</div>
                            <div>
                                <img src={T4twitter} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                <img src={T4telegram} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                <img src={T4Instagram} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                                <img src={T4trend} alt='log' height={'46px'} style={{ cursor: 'pointer' }} onClick={() => window.open(`${memewarpcastlink}`)}></img>

                            </div>

                        </div>
                        <div
                            style={{
                                position: "relative",
                                height: "100%",
                                width: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img src={memecoinavisionleftimage.length > 0 ? memecoinavisionleftimage : T4MemeImage1} alt="logo" height={'100%'} width={'100%'}></img>
                        </div>

                    </div>

                    <div
                        style={{


                            backgroundImage: memestepbackground == 0 ? `url(${memestepbgimage})` : 'url(${T4Section3bgImage})',
                            position: "relative",
                            width: '100%',
                            height: '100%',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',

                        }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                height: '100%',
                                width: '100%',

                                backgroundColor: memestepbackground == 1 ? `${memestepbgcolor}` : "#254a9a",
                                opacity: "0.9",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                padding: '50px',

                            }}

                        >
                            <div
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    height: "100%",
                                    width: "50%",
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}
                            >
                                <span
                                    style={{
                                        position: 'relative',


                                        textTransform: 'uppercase',
                                        fontWeight: '800',


                                        textAling: 'left',
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'fit-content',
                                        width: "90%",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: '81.93px',
                                    }}
                                >{t4step1}</span>
                                <img src={t4stepimage.length > 0 ? t4stepimage : T4MemeImage2} alt="logo" height={'100%'}></img>
                            </div>
                            <div
                                style={{
                                    color: 'white',
                                    position: 'relative',
                                    height: "100%",
                                    width: "50%",
                                    display: 'flex',
                                    alignItems: 'start',
                                    justifyContent: 'center',
                                    flexDirection: "column",
                                    marginTop: "30px",


                                }}

                            >
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "167.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',


                                    }}
                                >CREATE A WALLET</span>
                                <span
                                    style={{
                                        fontSize: "14.3px",
                                        width: '70%',
                                        fontFamily: "Inter",
                                        color: 'white',
                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',


                                    }}
                                >
                                    {t4step2}
                                </span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >Get some sol</span>
                                <span
                                    style={{
                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step3}</span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >
                                    GO TO JUPITER</span>
                                <span
                                    style={{

                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step4}</span>
                                <span
                                    style={{
                                        textTransformation: 'uppercase',
                                        fontWeight: '800',

                                        width: "157.6px",
                                        height: "29px",
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '17.1px',

                                    }}
                                >SWAP FOR PREMT</span>
                                <span
                                    style={{

                                        width: '70%',

                                        overflow: 'hidden',
                                        height: "fit-content",
                                        lineBreak: 'anywhere',
                                        fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Inter", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : "white", fontSize: memestepfontSize != 0 ? `${memestepfontSize}px` : '14.3px',

                                    }}
                                >{t4step5}</span>

                            </div>
                        </div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: memetokenomicsbackground == 1 ? `${memetokenomicsbgcolor}` : 'white',
                            padding: "40px",
                            backgroundImage: memetokenomicsbackground == 0 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '', objectFit: 'cover', backgroundSize: 'cover',
                        }}

                    >
                        <span
                            style={{
                                fontWeight: '800',
                                fontFamily: 'Inter',
                                color: memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A',

                                width: "281.8px",
                                height: "71.6px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden",
                                fontFamily: `${memecointokenfont}`, fontSize: memetokenfontSize != 0 ? `${memetokenfontSize}px` : "49.26px",
                            }}
                        >{t4token}</span>
                        <span
                            style={{

                                fontFamily: 'Inter',
                                color: '#d93731',
                                fontSize: "14.3px",
                                width: "116.8px",
                                height: "21.4px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: `${memecointokenfont}`, fontSize: memetokenfontSize != 0 ? `${memetokenfontSize}px` : "49.26px",
                                color: memecointoketextcolor != 'white' ? memecointoketextcolor : '#0B0F3A',

                            }}
                        >See more on X.</span>
                        <img alt="logo" src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T4MemeImage3} height={"60%"} width={"80%"}></img>
                    </div>


                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",

                            gap: "40px",
                            paddingTop: "46px",
                            backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#0b0f3a' : memefooterbgcolor : '',

                        }}
                    >
                        <div style={{

                            textTransformation: 'uppercase',
                            fontWeight: '800',


                            height: '71.6px',
                            width: '315.3px',
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: `${memefooterfontSize}px`, fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                        }}>{t4footer4}</div>
                        <div style={{
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: '70%',
                            gap: "40px",
                        }}>
                            <span
                                style={{



                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer1}

                            </span>
                            <span
                                style={{


                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer2}</span>
                            <span
                                style={{


                                    width: '274px',
                                    height: "fit-content",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    lineBreak: 'anywhere',
                                    color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '16px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                }}
                            >{t4footer3}</span>

                        </div>
                    </div>

                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#254a9a",

                        }}
                    >
                        <div
                            style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: "46px"
                            }}
                        >
                            <div
                                style={{
                                    position: "relative",
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingLeft: '90px',

                                }}
                            >
                                <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T4Navlogo} height={'119.4px'}></img>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "50%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "30px"
                                }}
                            >
                                <div>
                                    <img src={T4footertelegram} height={'89.3px'} style={{ cursor: "pointer" }} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                    <img src={T4footerTwitter} height={'89.3px'} style={{ cursor: "pointer" }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                    <img src={T4footerInstagram} height={'89.3px'} style={{ cursor: "pointer" }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                                    <img src={T4footertrend} height={'89.3px'} style={{ cursor: "pointer" }} onClick={() => window.open(`${memewarpcastlink}`)}></img>
                                </div>
                                <span
                                    style={{

                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '14.3px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter',

                                    }}
                                >Media contact:  realdolandPremt@proton.me</span>
                            </div>
                        </div>
                        <div style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",


                        }}>
                            <hr
                                style={{
                                    position: 'relative',
                                    color: 'white',
                                    height: '2px',
                                    overflow: ' hidden',
                                    width: '90%',
                                    lineHeight: '1px',
                                }}>

                            </hr>
                            <span style={{ color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '10px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter', }}>${memecoinsymbol} has no association with Donald Trump. This token is simply a meme coin with no
                                intrinsic value or expectation of financial return.</span>
                            <span style={{ color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : "white", fontSize: memefooterfontSize != 0 ? `${memefooterfontSize}px` : '10px', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : 'Inter', }}>© 2024 by doland Premt $PREMT. All rights reserved.</span>

                        </div>
                    </div> */}

                </div > : null}


                {/* --------------------------------------------------------t5------------------------------------------------ */}

                {selectMemeCoinTemplates == 5 ?
                    <div className={stylesT5.wrapper}>
                        {/* Header Section */}
                        {(memecoinsection >= 1 && memecoinsection <= 7) ? <UrlNav /> : null}

                        <section id='Navbar' className={stylesT5.header}
                            style={{
                                height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover',
                                backgroundColor: memebackground == 0 ? memenavbarbgcolor.length == 0 ? '#f6b400' : memenavbarbgcolor : '',
                                backgroundImage: memebackground == 1 ? memenavbgcolor.length !== 0 ? `url(${memenavbgcolor})` : '' : '',

                            }}>
                            <img src={memecoinlogoImage.length > 0 ? memecoinlogoImage : T5image1} alt="Ponke Logo" className={stylesT5.logo} />

                            <h1 className={stylesT5.poweredBySolana} style={{ color: memenavtextcolor.length > 0 ? `${memenavtextcolor}` : "#000", fontFamily: memecoinanvfont.length > 0 ? `${memecoinanvfont}` : "Helvetica Neue", }} >{t5Nav1}</h1>
                            <button className={stylesT5.button} style={{ color: memenavtextcolor.length > 0 ? `${memenavtextcolor}` : "white", fontFamily: memecoinanvfont.length > 0 ? `${memecoinanvfont}` : "Helvetica Neue", backgroundColor: memebuybuttonbackground == 0 ? memebuybuttonbackground.length == 0 ? '#f6b400' : buybuttonbgcolor : '', backgroundImage: memebuybuttonbackground == 1 ? buybuttnbgImage.length !== 0 ? `url(${buybuttnbgImage})` : '' : '', }} onClick={() => window.open(`${template2cmc}`)} >{t5nav2} {memecoinname}</button>
                        </section>


                        <div id='Hero'
                            className={stylesT5.hero}
                            style={{
                                position: "relative", width: "100%", height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",

                                backgroundColor: memeherobackground == 0 ? memeherobgcolor.length == 0 ? '#f6b400' : memeherobgcolor : '',
                                backgroundImage: memeherobackground == 1 ? memecoinaboutbgImage.length !== 0 ? `url(${memecoinaboutbgImage})` : '' : '',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                            }}
                        >
                            <div className={stylesT5.heroImage} style={{ position: "relative", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2} alt="Ponke Logo" height={"100%"} width={"90%"}></img>
                            </div>
                            <div className={stylesT5.heroText} style={{ position: "relative", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
                                <span
                                    style={{
                                        width: '90%',
                                        height: "fit-content",
                                        position: "relative",
                                        fontSize: '28.8px',
                                        color: memecoinabouttextcolor.length > 0 ? `${memecoinabouttextcolor}` : '#000',
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        overflow: "hidden",
                                        fontFamily: `${memecoinaboutfont}`,
                                    }}
                                >
                                    {memecoinname} {memecoinAbout}
                                </span>
                                <div style={{ position: "relative", width: "70%", height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center", }}>
                                    {/* <img src={buy.length > 0 ? buy : T5image3} alt="Ponke Logo" height={'50px'} onClick={() => window.open(`${template2cmc}`)}></img> */}
                                    <img src={twitterimage.length > 0 ? twitterimage : T5image4} alt="Ponke Logo" height={'50px'} width={'60px'} style={{ cursor: 'pointer' ,display:removemedia1==true?'none':'block'}} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                    <img src={telegraminage.length > 0 ? telegraminage : T5image5} alt="Ponke Logo" height={'50px'} width={'60px'} style={{ cursor: 'pointer',display:removemedia2==true?'none':'block' }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                    <img src={instagramimage.length > 0 ? instagramimage : T5image6} alt="Ponke Logo" height={'50px'} width={'60px'} style={{ backgroundColor: instagramimage.length > 0 ? 'transparent' : "#000", borderRadius: instagramimage.length > 0 ? '' : '50%', padding: instagramimage.length > 0 ? '' : '8px', cursor: 'pointer',display:removemedia3==true?'none':'block' }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                                </div>
                            </div>
                        </div>

                       


                        {/* Helmet Section */}
                        <section id='Vision' className={stylesT5.helmet}
                            style={{
                                backgroundColor: memeaboutbackground == 0 ? memeaboutbgcolor.length == 0 ? '#f6b400' : memeaboutbgcolor : '',
                                backgroundImage: memeaboutbackground == 1 ? memeaboutbgimage.length !== 0 ? `url(${memeaboutbgimage})` : '' : '',
                                height: '100%', width: '100%', objectFit: 'cover', backgroundSize: 'cover'
                            }}
                        >
                            <div>
                                <h2
                                    className={stylesT5.helmetText}
                                    style={{
                                        width: '100%',
                                        position: 'relative',




                                        textAlign: 'center',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: 'fit-content',
                                        // overflow: 'hidden',

                                        fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : "Helvetica Neue", color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#000',
                                    }}
                                >
                                    {t5about1}
                                </h2>
                            </div>
                            <div className={stylesT5.helmetDiv} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '50%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: "5%" }}>
                                    <img src={memecoinavisionrightimage.length > 0 ? memecoinavisionrightimage : T5image18} alt="T5image18" height={'80%'} width={'350px'}></img>
                                </div>
                                <div style={{ width: '50%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column", paddingLeft: "-5%" }}>
                                    <h3
                                        style={{
                                            width: '80%',
                                            position: 'relative',
                                            fontSize: '30px',



                                            textAlign: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: 'fit-content',
                                            overflow: 'hidden',

                                            fontFamily: memecoinvisionfont.length > 0 ? `${memecoinvisionfont}` : "Helvetica Neue", color: memecoinvisiontextcolor.length > 0 ? `${memecoinvisiontextcolor}` : '#000',

                                        }}
                                    >{t5about2}</h3>
                                    <img src={buy1.length > 0 ? buy1 : T5image19} alt="T5image19" width={'300px'} height={'100px'} onClick={() => window.open(`${template2cmc}`)}></img>
                                </div>

                            </div>
                        </section>



                        {/* Tokenomics Section */}
                        <section id='Token' className={stylesT5.tokenomics}
                            style={{
                                backgroundImage: memetokenomicsbackground == 1 ? memecointokonomicsbgimage.length > 0 ? `url(${memecointokonomicsbgimage})` : `` : '',
                                width: '100%', height: '100%', objectFit: 'cover', backgroundSize: 'cover',
                                backgroundColor: memetokenomicsbackground == 0 ? memetokenomicsbgcolor.length == 0 ? "#121212" : `${memetokenomicsbgcolor}` : '',
                            }}
                        >

                            <div style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}

                            >
                                <h3
                                    className={stylesT5.tokenomicsText}
                                    style={{


                                        width: '90%',
                                        height: "fit-content",

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#f6b400",
                                    }}
                                >{t5Token1}</h3>
                                <p
                                    style={{
                                        fontSize: '30px',
                                        width: '90%',
                                        height: "fit-content",

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#FFFFFF",

                                    }}
                                >{'$' + memecoinname} {t5Token3}</p>
                                <p
                                    style={{

                                        fontSize: '30px',
                                        width: '90%',
                                        height: "fit-content",

                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',

                                        overflow: 'hidden',
                                        textAlign: 'center',
                                        position: 'relative',
                                        fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#FFFFFF",

                                    }}
                                >{t5Token4}</p>
                            </div>
                            <div
                                className={stylesT5.tokenomicsDiv}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}

                            >
                                <div
                                    className={stylesT5.tokenomicsDiv1}
                                    style={{

                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        padding: "5%",
                                        gap: "10px"
                                    }}
                                >
                                    <img src={memecointokenomicsimage.length > 0 ? memecointokenomicsimage : T5image20} height={'90%'} width={"90%"}></img>

                                    {/* <img src={buy.length > 0 ? buy : T5image3} height={'70px'} width={'50%'} onClick={() => window.open(`${template2cmc}`)}></img> */}
                                </div>
                                <div
                                    className={stylesT5.tokenomicsDiv2}
                                    style={{

                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',

                                        gap: "10px",
                                    }}
                                >
                                    <img src={newiconsImage.length > 0 ? newiconsImage : T5image22} width={'40%'} height={'20%'}></img>
                                    <div
                                        style={{

                                            width: "90%",

                                            border: '4px solid #000',
                                            height: '136px',

                                            overflow: 'hidden',


                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            fontSize: '30px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            padding: "10px",
                                            fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#000",
                                            backgroundColor: t4statsbackgrounsd == '' ? '#f6b400' : t4statsbackgrounsd, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'

                                        }}

                                    >
                                        <p>{t1token}</p>
                                        <p style={{ fontSize: "20px" }}>{t5Token5}{memecoinname}</p>
                                    </div>
                                    <div
                                        style={{

                                            width: "90%",

                                            border: '4px solid #000',
                                            height: '136px',

                                            overflow: 'hidden',

                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            fontSize: '30px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            padding: "10px",
                                            fontFamily: memecointokenfont.length > 0 ? `${memecointokenfont}` : "Helvetica Neue", color: memecointoketextcolor.length > 0 ? `${memecointoketextcolor}` : "#000",
                                            backgroundColor: t4statsbackgrounsd == '' ? '#f6b400' : t4statsbackgrounsd, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)'

                                        }}
                                    >
                                        <p>{t1token1}</p>
                                        <p style={{ fontSize: "20px", lineBreak: "anywhere" }}>{t5Token6}</p>
                                    </div>

                                </div>


                            </div>


                        </section>


                        {/* How to Buy Section */}
                        <section id='HowTOBuy' className={stylesT5.howToBuy}
                            style={{
                                backgroundImage: memestepbackground == 1 ? `url(${memestepbgimage})` : '',
                                height: '100%', width: '100%', backgroundSize: 'cover', objectFit: 'cover',
                                backgroundColor: memestepbackground == 0 ? memestepbgcolor.length == 0 ? '#f6b400' : `${memestepbgcolor}` : ""
                            }}
                        >
                            <h2
                                style={{
                                    width: '70%',
                                    position: 'relative',
                                    fontSize: '86px',


                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',

                                    wordBreak: 'break-word',
                                    fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#000',
                                }}
                            >{stepheading}</h2>
                            <span
                                style={{
                                    width: '75%',
                                    position: 'relative',
                                    fontSize: '30px',

                                    fontFamily: 'Helvetica Neue',
                                    color: '#000',
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: 'fit-content',
                                    overflow: 'hidden',
                                    padding: '30px',
                                    wordBreak: 'break-word',
                                    fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#000',

                                }}
                            >
                                {t1stepheading}{memecoinname}</span>
                            <div className={stylesT5.buySteps}>

                                <div className={stylesT5.buyStep}
                                    style={{ background: `${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}

                                >
                                    <h3
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '20px',
                                            textAlign: 'center',



                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            overflow: 'hidden',
                                            textTransform: 'uppercase',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',

                                        }}
                                    >{step1heading}</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '12px',
                                            textAlign: 'center',


                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            overflow: 'hidden',

                                            padding: '10px',
                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step1}</p>
                                </div>

                                <div className={stylesT5.buyStep}
                                    style={{ background: `${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}
                                >
                                    <h3
                                        style={{
                                            textAlign: 'center',
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '20px',
                                            textTransform: 'uppercase',
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            overflow: 'hidden',


                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#ffff',

                                        }}
                                    >{step2heading}</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '12px',
                                            textAlign: 'center',



                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            padding: '10px',
                                            overflow: 'hidden',

                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step2}</p>
                                </div>
                                <div className={stylesT5.buyStep}
                                    style={{ background: `${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}
                                >
                                    <h3
                                        style={{
                                            textTransform: 'uppercase',
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '20px',
                                            textAlign: 'center',


                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            overflow: 'hidden',

                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',


                                        }}
                                    >{step3heading}{memecoinname}</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '12px',
                                            textAlign: 'center',


                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '50%',
                                            padding: '10px',
                                            overflow: 'hidden',

                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',

                                        }}
                                    >{t5step3}{memecoinname}</p>
                                </div>
                                <div className={stylesT5.buyStep}
                                    style={{ background: `${t4statsbackgrounsd.length ? t4statsbackgrounsd : '#1d1d1d'}`, border: `${statsborder}px solid ${statsbordercolor}`, borderRadius: `${statsborderradius}px`, boxShadow: statsshowdowrange ? `0px 0px ${statsshowdowrange}px 0px rgba(21, 21, 25, 0.21` : '0px 19px 30px 8px rgba(21, 21, 25, 0.21)' }}
                                >
                                    <h3
                                        style={{
                                            textTransform: 'uppercase',
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '20px',
                                            textAlign: 'center',



                                            display: 'flex',
                                            alignItems: 'center',

                                            height: '50%',
                                            overflow: 'hidden',

                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : '#fff',

                                        }}
                                    >{step4heading}{memecoinname}</h3>
                                    <p
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            fontSize: '12px',
                                            textAlign: 'center',


                                            display: 'flex',
                                            alignItems: 'center',

                                            height: '50%',
                                            padding: '10px',
                                            overflow: 'hidden',

                                            fontFamily: memecoinstepfont.length > 0 ? `${memecoinstepfont}` : "Helvetica Neue", color: memecoinsteptextcolor.length > 0 ? `${memecoinsteptextcolor}` : 'gba(255, 255, 255, 0.8)',
                                            justifyContent: 'center',
                                        }}
                                    >{t5step4}{memecoinname}</p>
                                </div>
                            </div>
                        </section>
                        {/* Socials Section */}
                        <section id='Footer' className={stylesT5.socials}
                            style={{ backgroundImage: memefootrbackground == 1 ? memefooterbgimage.length !== 0 ? `url(${memefooterbgimage})` : '' : '', objectFit: 'cover', backgroundSize: 'cover', backgroundColor: memefootrbackground == 0 ? memefooterbgcolor.length == 0 ? '#f6b400' : memefooterbgcolor : '', }}
                        >
                            <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <h3
                                    className={stylesT5.footertext}
                                    style={{
                                        width: "90%",


                                        height: "fit-content",

                                        overflow: 'hidden',
                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : '#000', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : "Helvetica Neue",
                                    }}
                                >{t1social}</h3>
                                <p
                                    style={{
                                        width: "90%",

                                        fontSize: "30px",
                                        height: "fit-content",

                                        overflow: 'hidden',
                                        color: memefootertextcolor.length > 0 ? `${memefootertextcolor}` : '#000', fontFamily: memefootertextfont.length > 0 ? `${memefootertextfont}` : "Helvetica Neue",

                                    }}
                                >{t1social1}{memecoinname} </p>
                            </div>
                            <div style={{ width: "30%", height: "100%", display: "flex", alignItems: "center", justifyContent: "space-evenly",gap:'20px' }}>
                                <img src={twitterimage.length > 0 ? twitterimage : T5image4} alt="Ponke Logo" height={'50px'} width={'50px'} style={{ cursor: 'pointer',display:removemedia1==true?'none':'block' }} onClick={() => window.open(`${memetwitterlink}`)}></img>
                                <img src={telegraminage.length > 0 ? telegraminage : T5image5} alt="Ponke Logo" height={'50px'} width={'50px'} style={{ cursor: 'pointer',display:removemedia2==true?'none':'block' }} onClick={() => window.open(`${memetelegramlink}`)}></img>
                                <img src={instagramimage.length > 0 ? instagramimage : T5image6} alt="Ponke Logo" height={'50px'} width={'50px'} style={{ backgroundColor: instagramimage.length > 0 ? 'transparent' : "#000", borderRadius: instagramimage.length > 0 ? '' : '50%', padding: '8px', cursor: 'pointer',display:removemedia3==true?'none':'block' }} onClick={() => window.open(`${memeinstagramlink}`)}></img>
                            </div>
                            <div style={{ display: "flex", width: '100%', alignItems: "center", justifyContent: "space-around", gap: "30px", padding: "2%" }}>
                                <div style={{ display: "flex", width: '50%', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                    <img src={memecoinAminitionImage.length > 0 ? memecoinAminitionImage : T5image2} height={'90%'} width={'70%'}></img>
                                </div>
                                <div style={{ display: "flex", width: '50%', alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column" }}>
                                    {/* <img src={buy.length > 0 ? buy : T5image3} height={'20%'} width={'40%'} onClick={() => window.open(`${template2cmc}`)}></img> */}
                                </div>


                            </div>

                        </section>
                    </div>
                    : null}
            </HelmetProvider >
        </>
    )
};

export default Memecoin 